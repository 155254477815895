import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useLocation, useNavigate } from "react-router-dom";
import {
    PaymentTypeEnum,
    respEnum,
    IntMinLevel,
    IntMax,
    IntCourtPriceMin
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikSwitch,
    FormikSearchSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";
import Utility from "../../../helper/utilityHelper";

const formValidations = yup.object({
    paymentTypeId: yup.number().required("Payment Type is required"),
    name: yup.string().required("Account Name is Required"),


});

export default function AddAccount() {
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    // const [PaymentTypes, setPaymentType] = useState([]);
    const [PaymentGateway, setPaymentGateway] = useState([]);
    const [id, setId] = useState(null);

    const formInitialValue = {
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
            GetAccountDetails(fetchedId);
        }
    }, []);

    useEffect(() => {
        // getPaymentType();
       // getPaymentGateway();
    }, []);

    const GetAccountDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `GetCubAccountList?id=${id}`
        );

        let d = response?.data?.[0];
        if (formikRef.current) {
            formikRef.current.setValues(d);
        }

    };


    // const getPaymentType = async () => {
    //     return new Promise(async (res) => {
    //         const response = await Common.ApiService.getInstance().request(
    //             "GetPaymentTypes"
    //         );
    //         const data = [
    //             { id: null, name: "Select Payment Type" },
    //             ...(response?.data ?? []),
    //         ];

    //         setPaymentType(data);
    //     });
    // };

    const getPaymentGateway = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetPaymentGatewayddl"
            );
            const data = [
                { id: null, name: "Select Payment Gateway" },
                ...(response?.data ?? []),
            ];

            setPaymentGateway(data);
        });
    };

    const onSubmitData = async (d, action) => {
        debugger
        let data = {
            ...d,
            id,
            paymentTypeId: +d.paymentTypeId,
            percentage: d.percentage ? +d.percentage : null,
            maxPocAllowed: d.maxPocAllowed ? +d.maxPocAllowed : null

        };
        debugger
        if (+d.paymentTypeId == PaymentTypeEnum.Payment_GateWay) {
            if (!d.merchantId) {
                Common.showToast("Merchant ID is required for Payment Gateway");
                return;
            }
            if (!d.merchantSecuredKey) {
                Common.showToast("Merchant Secured Key is required for Payment Gateway");
                return;
            }
            if (!d.paymentGatewayId) {
                Common.showToast("Payment Gateway ID is required for Payment Gateway");
                return;
            }
        }

        if (+d.paymentTypeId === PaymentTypeEnum.Pay_On_Club && !d.maxPocAllowed) {
            debugger
                Common.showToast("Max Poc Amount is Required for Pay On Club");
                return;
        }

        if (+d.paymentTypeId === PaymentTypeEnum.Online_Partial_Pay && !d.percentage) {
            Common.showToast("Percentage is Required for Online Partial Pay ");
            return;
        }

        if (+d.paymentTypeId === PaymentTypeEnum.Online || +d.paymentTypeId === PaymentTypeEnum.Payment_GateWay) {
            if (!d.bankName) {
                Common.showToast("Bank Name is required for Online/Payment Gateway");
                return;
            }
            if (!d.bankAccountNo) {
                Common.showToast("Bank Account Number is required for Online/Payment Gateway");
                return;
            }
            if (!d.bankAccountTitle) {
                Common.showToast("Bank Account Title is required for Online/Payment Gateway");
                return;
            }
            if (!d.bankLocationName) {
                Common.showToast("Bank Location Name is required for Online/Payment Gateway");
                return;
            }
            if (!d.branchCode) {
                Common.showToast("Branch Code is required for Online/Payment Gateway");
                return;
            }
        }
        
        let response = await Common.ApiService.getInstance().request(
            "AddUpdateClubAccount",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/ClubAccounts");
            }, 1000);
        }
    };

    const resetPaymentFields = (value, formikProps) => {

        if (+value == PaymentTypeEnum.Cash) {
            formikProps.setFieldValue("bankName", "");
            formikProps.setFieldValue("bankAccountNo", "");
            formikProps.setFieldValue("bankAccountTitle", "");
            formikProps.setFieldValue("bankLocationName", "");
            formikProps.setFieldValue("branchCode", "");
            formikProps.setFieldValue("merchantId", "");
            formikProps.setFieldValue("merchantSecuredKey", "");
            formikProps.setFieldValue("paymentGatewayId", null);
        }
        else if (+value == PaymentTypeEnum.Online) {
            formikProps.setFieldValue("merchantId", null);
            formikProps.setFieldValue("merchantSecuredKey", null);
            formikProps.setFieldValue("paymentGatewayId", null);
        }
    };


    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="space-y-6 mb-5">
                            <Card title={id ? "Update Club Accounts" : "Create Club Accounts"}>
                                <div className="md:grid md:grid-cols-10 gap-4 p-5" >
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"paymentTypeId"}
                                            disabled={id}
                                            data={[
                                                { id: PaymentTypeEnum.Cash, name: "Cash" },
                                                { id: PaymentTypeEnum.Online, name: "Online" },
                                                ...(id
                                                    ? [
                                                        { id: PaymentTypeEnum.Pay_On_Club, name: "Pay On Club" },
                                                        { id: PaymentTypeEnum.Online_Partial_Pay, name: "Online Partial Pay" },
                                                    ]
                                                    : []),
                                            ]}
                                            placeholder={"Select Payment Type"}
                                            label={"Payment Type"}
                                            onChange={(e) => {
                                                resetPaymentFields(e, formikProps);
                                            }}
                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"name"}
                                            placeholder={"Account Name"}
                                            label={"Account Name"}
                                            maxLength="20"
                                            disabled={
                                                formikProps.values.paymentTypeId !== PaymentTypeEnum.Cash &&
                                                formikProps.values.paymentTypeId !== PaymentTypeEnum.Online
                                            }
                                        />
                                    </div>


                                    {(formikProps.values.paymentTypeId == PaymentTypeEnum.Online || formikProps.values.paymentTypeId == PaymentTypeEnum.Payment_GateWay) && (
                                      <>
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"bankName"}
                                            placeholder={"Bank Name"}
                                            label={"Bank Name"}
                                            maxLength="30"
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"bankAccountNo"}
                                            placeholder={"Bank Account No"}
                                            label={"Bank Account No"}
                                            maxLength="30"
                                        />
                                    </div>
                                  
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"bankAccountTitle"}
                                            placeholder={"Bank Account Title"}
                                            label={"Bank Account Title"}
                                            maxLength="30"
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"bankLocationName"}
                                            placeholder={"Bank Location"}
                                            label={"Bank Location"}
                                            maxLength="30"
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"branchCode"}
                                            placeholder={"Branch Code"}
                                            label={"Branch Code"}
                                            maxLength="20"
                                        />
                                    </div>
                                        </>
                                    )}
                                    {formikProps.values.paymentTypeId == PaymentTypeEnum.Payment_GateWay && (
                                        <>

                                            <div className="flex flex-col col-span-2 gap-2">
                                                <FormikSearchSelect
                                                    formikProps={formikProps}
                                                    name={"paymentGatewayId"}
                                                    data={PaymentGateway}
                                                    placeholder={"Select Gateway"}
                                                    label={"Payment Gateway"}
                                                />
                                            </div>
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"merchantId"}
                                            placeholder={"Merchant ID"}
                                            label={"Merchant ID"}
                                            maxLength="20"
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"merchantSecuredKey"}
                                            placeholder={"Merchant Secured Key"}
                                            label={"Merchant Secured Key"}
                                            maxLength="30"
                                        />
                                    </div>

                                        </>
                                    )}
                                    {(formikProps.values.paymentTypeId == PaymentTypeEnum.Online || formikProps.values.paymentTypeId == PaymentTypeEnum.Payment_GateWay) && (
                                        <>
                                    <div className="flex flex-col col-span-2 gap-1">
                                        <FormikSwitch
                                            formikProps={formikProps}
                                            name={"isAcceptOnline"}
                                            label={"Is Accept Online"}
                                            style={{ marginTop: '7px' }}
                                        />
                                    </div>
                                        </>
                                    )}
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikSwitch
                                            formikProps={formikProps}
                                            name={"isActive"}
                                            label={"Is Active"}
                                            style={{ marginTop: '7px' }}
                                        />
                                    </div>

                                    {(formikProps.values.paymentTypeId == PaymentTypeEnum.Pay_On_Club) && (
                                        <>
                                            <div className="flex flex-col col-span-2 gap-1">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"maxPocAllowed"}
                                                    placeholder={"Enter Max Poc Allowed"}
                                                    label={"Max Poc Allowed"}
                                                    type={"number"}
                                                    max={IntMax}
                                                    min={IntMinLevel}
                                                    onChange={(value) => {
                                                        if (value < IntMinLevel && value != "") {
                                                            formikProps.setFieldValue("maxPocAllowed", IntMinLevel);
                                                        } else if (value > IntMax) {
                                                            Common.showToast(
                                                                `Discount value cannot be greater than ${IntMax}`
                                                            );
                                                            formikProps.setFieldValue("maxPocAllowed", "");
                                                        }else {
                                                            formikProps.setFieldValue("maxPocAllowed", value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}


                                    {(formikProps.values.paymentTypeId == PaymentTypeEnum.Online_Partial_Pay) && (
                                        <>
                                            <div className="flex flex-col col-span-2 gap-1">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"percentage"}
                                                    placeholder={"Enter Percentage"}
                                                    label={"Percentage"}
                                                    type={"number"}
                                                    max={99}
                                                    min={IntMinLevel}
                                                    onChange={(value) => {
                                                        if (value < IntMinLevel && value != "") {
                                                            formikProps.setFieldValue("percentage", IntMinLevel);
                                                        } else if (value > 99) {
                                                            Common.showToast(
                                                                `Percentage cannot be greater than 99`
                                                            );
                                                            formikProps.setFieldValue("percentage", "");
                                                        } else {
                                                            formikProps.setFieldValue("percentage", value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                
                                </div>
                            </Card>
                        </div>

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>

                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
