import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ClubPaymentLogTypeEnum,
    respEnum,
    IntMinLevel,
    IntMax,
} from "../../../constants/appConstant";
import {
    BreadcrumbItem,
    Breadcrumbs,
} from "../../../components/ui/breadcrumbs";
import {
    FormikInput,
    FormikSearchSelect,
    FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";

const formValidations = yup.object({
    playerId: yup.number().required("Customer is Required"),
    type: yup.number().required("Type is required"),
    amount: yup.number().required("Amount is required"),
    clubAccountId: yup.object().required("Payment Account is Requied"),
});

export default function AddPayments() {
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const [customerList, setCustomerList] = useState([]);
    /*    const [clubAccountId, setClubAccountId] = useState(null);*/
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [id, setId] = useState(null);

    const formInitialValue = {};

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
            GetDetails(fetchedId);
        }
    }, []);

    useEffect(() => {
        getCustomers();
        Common.delay();
        getClubPaymentTypes();
    }, []);

    const getCustomers = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "ClubPlayersddl",
                {},
                "Get",
                false
            );
            const customerData = response?.data ?? [];

            const updatedCustomerList = [
                { id: null, name: "Select Customer", phone: "" },
                ...customerData.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                })),
            ];
            setCustomerList(updatedCustomerList);

            setTimeout(res, 1000);
        });
    };

    const getClubPaymentTypes = async () => {

        const response = await Common.ApiService.getInstance().request(
            "GetClubPaymentTypes"
        );

        if (response?.status == respEnum.Success) {
            setPaymentTypeList(response?.data ?? []);
        }
    };

    const getCurrentBalance = async (playerId) => {
        if (!playerId) {
            formikRef.current.setFieldValue("balance", "");
            return;
        }
        let response = await Common.ApiService.getInstance().request(
            `GetCustomerBookingBalance?playerId=${playerId}`
        );

        let d = response?.data ? response?.data : 0;
        if (formikRef.current) {
            formikRef.current.setFieldValue("balance", d);
        }

    };

    const GetDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `Getpayment?id=${id}`
        );

        let d = response?.data?.[0];
        if (formikRef.current) {
            formikRef.current.setValues(d);
        }
    };

    const onSubmitData = async (d, action) => {
        let data = {
            ...d,
            id,
            amount: +d.amount,
            playerId: +d.playerId,
            type: +d.type,
            clubAccountId: +d.clubAccountId.value,
        };

        if (data.type == ClubPaymentLogTypeEnum.Payment_Refund && data.amount > data.balance) {
            Common.showToast(`Amount cannot be greater than ${data.balance} when payment refund`);
            return;
        }

        let response = await Common.ApiService.getInstance().request(
            "AddClubPayment",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/payments");
            }, 1000);
        }
    };

    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="flex flex-wrap mb-3">
                            <div className="text-xl font-medium text-default-900 flex-1">
                            </div>
                            <div className="flex-none">
                                <Breadcrumbs>
                                    <BreadcrumbItem
                                        onClick={() =>
                                            navigate("/app/payments")
                                        }
                                    >
                                        Payments 
                                    </BreadcrumbItem>
                                    <BreadcrumbItem> Receive/Refund </BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div className="space-y-6 mb-5">
                            <Card title={id ? " Update Payment Voucher" : "Receive/Refund Payment"}>
                                <div className="md:grid md:grid-cols-10 gap-4 p-5" >
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"playerId"}
                                            disabled={id}
                                            data={customerList}
                                            placeholder={"Select Customer"}
                                            label={"Customer"}
                                            onChange={(e) => {
                                                getCurrentBalance(e);
                                            }}
                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"balance"}
                                            placeholder={"Current Balance"}
                                            label={"Current Balance"}
                                            disabled={true}

                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"type"}
                                            disabled={id}
                                            data={[
                                                { id: ClubPaymentLogTypeEnum.Payment_Received, name: "Payment Received" },
                                                { id: ClubPaymentLogTypeEnum.Payment_Refund, name: "Payment Refund" },
                                            ]}
                                            placeholder={"Select Type"}
                                            label={"Type"}
                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-1">

                                        <FormikSearchSectionSelect
                                            formikProps={formikProps}
                                            name={"clubAccountId"}
                                            label={"Payment Account"}
                                            data={paymentTypeList}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-1">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"amount"}
                                            placeholder={"Enter Amount"}
                                            label={"Amount"}
                                            type={"number"}
                                            max={formikProps.values.type === ClubPaymentLogTypeEnum.Payment_Received
                                                ? IntMax : formikProps.values.balance}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("amount", IntMinLevel);
                                                } else if (formikProps.values.type !== ClubPaymentLogTypeEnum.Payment_Received && value > formikProps.values.balance) {
                                                    Common.showToast(
                                                        `Amount cannot be greater than balance`
                                                    );
                                                    formikProps.setFieldValue("amount", "");
                                                } else if (formikProps.values.type == ClubPaymentLogTypeEnum.Payment_Received && value > IntMax) {
                                                    Common.showToast(
                                                        `Amount cannot be greater than ${IntMax}`
                                                    );
                                                    formikProps.setFieldValue("amount", "");

                                                } else {
                                                    formikProps.setFieldValue("amount", value);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>

                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
