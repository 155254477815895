import { Formik } from "formik";

import * as yup from "yup";
import { useEffect, useRef } from "react";
import { Button } from "../../../../components/ui/button";
import Card from "../../../../components/ui/card-snippet";
import { useNavigate } from "react-router-dom";
import {
  FormikInput,
  FormikTextArea,
} from "../../../../components/formik-components";
import Common from "../../../../helper/common";
import {
  HolidayTypeEnum,
  ResultStatus,
  ToCSharpFormat,
} from "../../../../constants/appConstant";
import { useQueryParams } from "../../../../contexts/queryParamsContext";

const formInitialValue = {};

const formValidations = yup.object({
  startDate: yup
    .date()
    .min(new Date(), "Start date cannot be in the past")
    .required("Start-date Time is required")
    .test(
      "is-valid-time",
      "Time of the start date format should be in 00, 30 or 23:59 format",
      (value) => Common.Utility.checkValidTimeOfDate(value)
    )
    .test(
      "is-future-time",
      "Time of the start date cannot be in the past",
      Common.Utility.checkPreviousTimeOfDate
    ),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date cannot be before the start date")
    .required("End-date Time is required")
    .test(
      "is-valid-time",
      "Time of the end date format should be in 00, 30 or 23:59 format",
      (value) => Common.Utility.checkValidTimeOfDate(value)
    )
    .test(
      "is-future-time",
      "Time of the end date cannot be in the past",
      Common.Utility.checkPreviousTimeOfDate
    ),
  reason: yup.string().trim().min(10).max(200).required("Reason is Required"),
});

export default function AddClubHoliday() {
  const formikRef = useRef();

  const { clubHolidayId } = useQueryParams().params;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (clubHolidayId) {
        GetHolidayDetails(clubHolidayId);
      }
    };
    fetchData();
  }, [clubHolidayId]);

  const GetHolidayDetails = async (id) => {
    let response = await Common.ApiService.getInstance().request(
      `GetCluborCourtHolidays`,
      { id },
      "POST"
    );

    if (response?.status == ResultStatus.Success) {
      let d = response?.data?.[0]?.data?.[0];
      if (d && formikRef.current) {
        formikRef.current.setValues(d);
      }
    }
  };


  const onSubmitData = async (d, action) => {
    let data = {
      id: clubHolidayId,
        ...d,
        type: HolidayTypeEnum.Club,
      startDate: Common.moment(`${d.startDate}`).format(ToCSharpFormat),
      endDate: Common.moment(`${d.endDate}`).format(ToCSharpFormat),
    };

    if (data.startDate >= data.endDate) {
      Common.showToast("EndTime must be greater");
      return;
    }

    let response = await Common.ApiService.getInstance().request(
      "AddUpdateCluborCourtHoliday",
      data,
      "POST"
      );
    debugger
    action.setSubmitting(false);
    if (response.status == ResultStatus.Success) {
      Common.SweetAlert.alert(response.message);
      setTimeout(() => {
          navigate("/app/clubholidays");
      }, 1000);
    }
  };

  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitData}
    >
      {(formikProps) => {
        formikRef.current = formikProps;
        return (
          <>
            <div className="space-y-6">
               <Card title={`${clubHolidayId ? "Update" : "Add"} Club Holiday`}>
                <div className="md:grid md:grid-cols-3  p-3 gap-3 mt-3">
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"startDate"}
                      placeholder={""}
                      label={"From Date"}
                      type={"datetime-local"}
                      min={Common.Utility.todayDatetime}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"endDate"}
                      placeholder={""}
                      label={"Till Date"}
                      type={"datetime-local"}
                      min={
                        formikProps.values.startDate ||
                        Common.Utility.todayDatetime
                      }
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <FormikTextArea
                      formikProps={formikProps}
                      name={"reason"}
                      placeholder={"Type..."}
                      type={"textarea"}
                      label={"Close Reason"}
                      maxLength={200}
                    />
                  </div>
                </div>
              </Card>
            </div>

            <div className="grid grid-cols-6 mt-5">
              <Button
                disabled={formikProps.isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                type="button"
              >
                {formikProps.isSubmitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
