import * as yup from "yup";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import { FormikInput, FormikSelect, FormikTextArea } from "../../../components/formik-components";
import { respEnum, PushNotificationTypeEnum } from "../../../constants/appConstant";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { useNavigate } from "react-router-dom";

const FormikInitialValues = {};

const formikValidation = yup.object({
    content: yup.string().required("Content is required").max(120, "Content must not exceed 120 characters"),
    title: yup.string().required("Title is required").max(50, "Title must not exceed 50 characters"),
    type: yup.number().required("Type is required"),
});


const AddNotifications = ({ id, onClose, open, setOpen }) => {
    const formikRef = useRef();
    const dialogRef = useRef();
    const navigate = useNavigate();
    const [isFormikInitialized, setIsFormikInitialized] = useState(false);

    useEffect(() => {
        setIsFormikInitialized(open);
    }, [open]);

    const onSubmitData = async (d, action) => {
        let data = {
            ...d,
            id,
        };
        let response = await Common.ApiService.getInstance().request(
            "SendPushNotificationToUsers",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status === respEnum.Success) {
            onClose(response.data);
            setOpen(false);
            if (!onClose) {
                navigate("/app/Notifications", { replace: true });
            }
        }
    };

    const getDataInfo = async () => {
        let response = await Common.ApiService.getInstance().request(
            `GetNotificationData?id=${id}`
        );
        if (response.status === respEnum.Success && response.data.length > 0) {
            formikRef.current.setValues(response.data[0]);
        }
    };

    useEffect(() => {
        if (id) {
            getDataInfo();
        }
    }, [id]);

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button
                        //onClick={() => setOpen(true)}
                        size="sm"
                        variant="primary"
                        className="flex items-center gap-2"
                    >
                        <Icon icon="heroicons:plus-20-solid" className="h-4 w-4" />
                        Add Notification
                    </Button>
                </DialogTrigger>
                <DialogContent ref={dialogRef}>
                    <DialogHeader>
                        <DialogTitle className="text-base font-medium ">
                            {!id ? "Add" : "Update"} Notification
                        </DialogTitle>
                    </DialogHeader>

                    <div className="text-sm text-default-500  space-y-2">
                        <Formik
                            innerRef={formikRef}
                            onSubmit={onSubmitData}
                            initialValues={FormikInitialValues}
                            validationSchema={formikValidation}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <div className="md:grid md:grid-cols-1 gap-6 mt-6 space-y-6 md:space-y-0">
                                    <div className="col-span-1">
                                        <div className="md:grid md:grid-cols-1  gap-6 mt-6 space-y-6 md:space-y-0">
                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"title"}
                                                    label={"Title"}
                                                    placeholder={"Title"}
                                                    maxLength="50"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <FormikTextArea
                                                    formikProps={formikProps}
                                                    name={"content"}
                                                    label={"Content"}
                                                    placeholder={"Content"}
                                                    maxLength="120"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-2">

                                                <FormikSelect
                                                    formikProps={formikProps}
                                                    name={"type"}
                                                    placeholder={"Type"}
                                                    label={"Type"}
                                                    data={[
                                                        { id: PushNotificationTypeEnum.Promotion, name: "Promotion" },
                                                        { id: PushNotificationTypeEnum.Alert, name: "Alert" },
                                                        { id: PushNotificationTypeEnum.Reminder, name: "Reminder" },
                                                    ]}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                    <DialogFooter className="mt-8 gap-2">
                        <DialogClose asChild>
                            <Button type="button" variant="outline">
                                Close
                            </Button>
                        </DialogClose>

                        {
                        // isFormikInitialized &&
                        //  formikRef.current && 
                         (
                            <Button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikRef.current?.handleSubmit();
                                }}
                            >
                                Add
                                {/* {formikRef.current.isSubmitting
                                    ? "Saving..."
                                    : !id
                                        ? "Add"
                                        : "Update"} */}
                            </Button>
                        )}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddNotifications;
