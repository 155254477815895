import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";
import { respEnum, ClubPaymentLogTypeEnum, ClubPaymentLogTransactionNatureEnum } from "../../constants/appConstant";
import {
    AppSelect,
    AppSearchSelect,
    AppInput,
} from "../../components/appComponents";

const Payments = () => {
    const [data, setData] = useState({});
    var [fromDate, setfromDate] = useState(null);
    var [tillDate, settillDate] = useState(null);
    const [playerId, setplayerId] = useState(null);
    const [customerList, setCustomerList] = useState([]);
 //   const [customerId, setCustomerId] = useState(null);
    const [type, settype] = useState(null);
 // const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

    useEffect(() => {
        getData(1);
        getCustomers();
    }, []);

    useEffect(() => {
        getData(1);
        getCustomers();
    }, [playerId, fromDate, tillDate, type]);

    const resetFilters = () => {
        setfromDate(null);
        settillDate(null);
        setplayerId(null);
        settype(null);
    };

    const getData = async (
        pageNumber,
        searchTerm = "",
        recordsToDisplay = null
    ) => {
        if (fromDate == "") { fromDate = null; }
        if (tillDate == "") { tillDate = null; }
        const response = await Common.ApiService.getInstance().request(
            "GetClubPaymentLogs",
            {
                pageNumber: pageNumber,
                q: searchTerm,
                isactive: true,
                recordsToDisplay,
                playerId,
                fromDate,
                tillDate,
                type,
            },
            "Post"
        );
        if (response?.data) {
            debugger
            let obj = {
                ...response.data[0],
                data: response.data[0].data.map((item) => ({
                    ...item,
                    createdOn: Common.Utility.ConvertTime(item.createdOn),
                    updatedOn: item.updatedOn ? Common.Utility.ConvertTime(item.updatedOn) : "-",
                    typeId: item.type,
                    type: Common.Utility.getKeyByValue(
                        ClubPaymentLogTypeEnum,
                        item.type
                    ),
                    // transactionNature: Common.Utility.getKeyByValue(
                    //     ClubPaymentLogTransactionNatureEnum,
                    //     item.transactionNature
                    // ),
                })),
            };

            setData(obj);
        }
    };


    const getCustomers = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "ClubPlayersddl",
                {},
                "Get",
                false
            );
            const customerData = response?.data ?? [];

            const updatedCustomerList = [
                { id: null, name: "Select Customer", phone: "" },
                ...customerData.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                })),
            ];
            setCustomerList(updatedCustomerList);

            setTimeout(res, 1000);
        });
    };


    const columns = [
      { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "Customer", type: "text", isVisible: true, key: "playerName" },
      //  { title: "Type", type: "text", isVisible: true, key: "type" },
       
        { title: "Amount", type: "text", isVisible: true, key: "amount" },
        { title: "Payment Type", type: "text", isVisible: true, key: "paymentType" },
        { title: "Payment Account", type: "text", isVisible: true, key: "clubAccountName" },
        {
            title: "Type",
            type: "text",
            key: "type",
            isVisible: true,
            render: (record) =>
                Common.Utility.renderTypeBadge(
                    record.typeId,
                    record.type
                ),
        },
        //{ title: "Transaction Nature", type: "text", isVisible: true, key: "transactionNature" },
  ];

  //const actions = [
  //  {
  //          type: "edit",
  //          onClick: (val) => {
  //            global.log(val.id)
  //            navigate(`/app/addAccount?id=${val.id}`, {});
  //        },
  //        visibleProp: "canEdit",
  //        visibleValue: true
  //  },
  //];



  //const _addUpdateTrigger = () => (
  //  <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
  //    <Icon icon="heroicons:pencil" className="h-4 w-4" />
  //  </Button>
  //);


  const _addBtn = () => (
      <Button onClick={() => navigate("/app/addpayments")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Payment
    </Button>
  );

    return (
        <>
            <div className="md:grid md:grid-cols-6 gap-3 ">

                <AppInput
                    type={"date"}
                    value={fromDate}
                    onChange={(e) => {
                        const selectedstartDate = e;
                        if (tillDate && selectedstartDate > tillDate) {
                            Common.showToast("Start Time must be lesser than End Time", "Error", "info");
                            setfromDate(null);
                        } else {
                            setfromDate(selectedstartDate);
                        }
                    }}
                    label="From"
                    className="mb-3 col-span-1 pl-6"
                />

                <AppInput
                    type={"date"}
                    value={tillDate}
                    onChange={(e) => {
                        const selectedEndDate = e;
                        if (selectedEndDate < fromDate) {
                            Common.showToast("End Time must be greater", "Error", "info");
                            settillDate("");
                        } else {
                            settillDate(selectedEndDate);
                        }
                    }}
                    label="Till"
                    className="mb-3 col-span-1"
                />

                <AppSearchSelect
                    value={playerId}
                    onChange={setplayerId}
                    placeholder={"Select Customer"}
                    label={"Customer"}
                    data={customerList}
                    className={"mb-3 col-span-1 "}
                />
                <AppSelect
                    value={type}
                    onChange={settype}
                    label="Type"
                    data={[
                        { id: null, name: "Select" },
                        ...Common.Utility.enumToArray(ClubPaymentLogTypeEnum),
                    ]}
                    className="mb-3 col-span-1"
                />

                <Button className="mt-6 h-8" onClick={resetFilters}>
                    Reset Filters
                </Button>
            </div>

            {data?.data && (
                <AdvanceTable
                    title={"Receive/Refund Payments"}
                    columns={columns.filter((item) => item.isVisible)}
                    data={data}
                    getData={getData}
                    showPagination
                    showAddButton
                    addButton={_addBtn}
                    //actionButtons={actions}
                    //updateTrigger={_addUpdateTrigger}
                   // showExportoptions
                />
            )}
        </>
    );
};

export default Payments;
