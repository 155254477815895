import React, { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormikInput } from "./index"

const PhoneInputField = ({ formikProps, disabled, ...props }) => {
    const phoneInputRef = useRef(null);

    useEffect(() => {
        if (phoneInputRef.current) {
            const dropdownButton = phoneInputRef.current.dropdownContainerRef;

            if (dropdownButton) {
                const handleDropdownClick = () => {
                    const currentPhoneCode = formikProps.values.phoneCode || "+92";
                    formikProps.setFieldValue("phone", currentPhoneCode);
                    formikProps.setFieldValue("phoneNum", "");
                };

                dropdownButton.addEventListener("click", handleDropdownClick);
                return () => {
                    dropdownButton.removeEventListener("click", handleDropdownClick);
                };
            }
        }
    }, [formikProps]);

    return (
        <div className="flex flex-col gap-2">
            <label htmlFor="phone" className="font-medium">
                Phone
            </label>
            {disabled ? (
                <FormikInput
                    formikProps={formikProps}
                    disabled={true}
                />
            ) : (
              
                <PhoneInput
                    ref={phoneInputRef}
                    country={"pk"}
                    inputProps={{
                        name: "phoneNum",
                        required: true,
                    }}
                    value={formikProps.values.phone}
                    onChange={(value, countryData) => {
                        const phoneCode = `+${countryData.dialCode}`;
                        const phoneNum = value.replace(countryData.dialCode, "").trim();
                        formikProps.setFieldValue("phoneCode", phoneCode);
                        formikProps.setFieldValue("phoneNum", phoneNum);
                        formikProps.setFieldValue("phone", value);
                    }}
                    inputStyle={{
                        width: "100%",
                        fontSize: "11px",
                        padding: "8px",
                    }}
                    buttonStyle={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontSize: "11px",
                    }}
                    placeholder="3081234567"
                    {...props}
                />
            )}
            {formikProps.errors.phone && formikProps.touched.phone && (
                <div className="text-red-500 text-sm">{formikProps.errors.phone}</div>
            )}
        </div>
    );
};

export default PhoneInputField;
