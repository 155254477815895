import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useLocation, useNavigate } from "react-router-dom";
import {
    PromoCodeDiscountTypeEnum,
    respEnum,
    IntMax,
    DateminFormat,
    IntMin,
    IntCourtPriceMin,
    IntMinLevel,
    RoleEnum,
    DateTimeminFormat,
    ToCSharpFormat,
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikSwitch,
    FormikSearchSelect,
    FormikDate,
    FormikSelect,
    PhoneInputField,
} from "../../../components/formik-components";
import Common from "../../../helper/common";
import { useQueryParams } from "../../../contexts/queryParamsContext";

const formValidations = yup.object({
    code: yup.string().required("Code is required"),
    discountType: yup.number().required("Discount Type is required"),
    discountValue: yup.number().required("Discount value is required").min(IntMinLevel).max(IntMax),
    minOrderAmount: yup.number().min(IntMinLevel).max(IntMax).required("Min Order is required"),
    //   maxDiscount: yup.number().min(IntMinLevel).max(IntMax).required("Max Order is required"),
    maxDiscount: yup
        .number()
        .min(IntMinLevel)
        .max(IntMax)
        .test(
            "is-required-if-percentage",
            "Max Discount is required when Discount Type is Percentage",
            function (value) {
                const { discountType } = this.parent; // Access sibling field value
                if (discountType === PromoCodeDiscountTypeEnum.Percentage) {
                    return value !== undefined && value !== null && value !== "";
                }
                return true; // Skip validation for other discount types
            }
        ),
    usageLimit: yup.number().min(IntMinLevel).max(IntMax).required("Usage Limit is required"),
    userUsageLimit: yup
        .number()
        .required("User Usage Limit is required")
        .min(IntMinLevel)
        .max(IntMax)
        .test(
            "is-less-than-usageLimit",
            "User usage limit cannot be greater than usage limit",
            function (value) {
                const { usageLimit } = this.parent; // Access sibling field value
                return usageLimit === null || usageLimit === undefined || value <= usageLimit;
            }
        ),
    validFrom: yup
        .date()
        //  .min(Common.Utility.today, "Date Cannot be in the past ")
        .required("Valid from is required")
        .test(
            "is-valid-time",
            "Time of the valid from format should be in 00, 30 or 23:59 format",
            (value) => Common.Utility.checkValidTimeOfDate(value)
        ),
    // .test(
    //     "is-future-time",
    //     "Time of the valid From  cannot be in the past",
    //     Common.Utility.checkPreviousTimeOfDate
    // ),

    validTo: yup
        .date()
        .min(yup.ref("validFrom"), "End date cannot be before the start date")
        .required("Valid To is required")
    //.test(
    //    "is-valid-time",
    //    "Time of the end date format should be in 00, 30 or 23:59 format",
    //    (value) => Common.Utility.checkValidTimeOfDate(value)
    //),
    // .test(
    //     "is-future-time",
    //     "Time of the valid to cannot be in the past",
    //     Common.Utility.checkPreviousTimeOfDate
    // ),

});

export default function AddPromoCodes() {
    const navigate = useNavigate();
    const formikRef = useRef();
    const [roleId, setRoleId] = useState(null);
    const [clubs, setClubs] = useState([]);
    const location = useLocation();
    let isRequested = false;
    //const { id, setId } = useQueryParams();
    const id = null;

    useEffect(() => {
        (async () => {
            setRoleId(global.user.roleId);
        })();
    }, []);

    useEffect(() => {
        getClubs();
    }, []);

    //useEffect(() => {
    //    if (id) {
    //        GetPromoCodeDetail();
    //    }

    //    return () => {
    //        setId(null);
    //    }
    //}, [id]);


    const formInitialValue = {
        validFrom: id ? DateTimeminFormat : Common.moment().format(DateTimeminFormat)
    };


    const getClubs = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetClubsDDL"
            );
            const data = [
                { id: null, name: "Select Club" },
                ...(response?.data ?? []),
            ];

            setClubs(data);
        });
    };

    const handlePhoneBlur = async (phone, formikProps) => {
        if (!formikProps.values.phoneNum || !phone || id) {
            formikRef.current.setFieldValue("playerId", null);
            formikRef.current.setFieldValue("playerName", "");
            return;
        }
        phone = formikProps.values.phoneNum.trim();
        var phoneCode = formikProps.values.phoneCode.replace("+", "").trim();
        if (isRequested) {
            return;
        }

        isRequested = true;
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                `GetAppCustomer?phoneCode=${phoneCode}&phone=${phone}`
            );
        
            if (response?.status == respEnum.Success && response.data) {
                formikRef.current.setFieldValue("playerId", response.data.id);
                formikRef.current.setFieldValue("playerName", response.data.name);
            } else {
                formikRef.current.setFieldValue("playerId", null);
                formikRef.current.setFieldValue("playerName", "");
            }
            isRequested = false;
            setTimeout(res, 1000);
        });
    };

    const GetPromoCodeDetail = async () => {

        let response = await Common.ApiService.getInstance().request(
            `GetPromoCodeDetail?id=${id}`
        );
       
        if (response.status == respEnum.Success && response?.data?.length > 0) {
            let d = response?.data?.[0];
            if (formikRef.current) {
                formikRef.current.setValues(d);
            }
        }

    };

    const onSubmitData = async (d, action) => {
        debugger;
        let data = {
            ...d,
            discountType: +d.discountType,
            minOrderAmount: d.minOrderAmount ? +d.minOrderAmount : null,
            maxDiscount: d.maxDiscount ? +d.maxDiscount : null,
            discountValue: d.discountValue ? +d.discountValue : null,
            clubId: d.clubId ? +d.clubId : null,
            usageLimit: d.usageLimit ? +d.usageLimit : null,
            userUsageLimit: d.userUsageLimit ? +d.userUsageLimit : null,
            playerId: d.playerId ? +d.playerId : null,
            validFrom: Common.moment(`${d.validFrom}`).format(ToCSharpFormat),
            validTo: Common.moment(`${d.validTo}`).format(ToCSharpFormat),
        };

        if (data.discountType == PromoCodeDiscountTypeEnum.Percentage && data.discountValue > 100) {
            Common.showToast("Percentage so discount value should be less than 100 ");
            return;
        }
        if (data.validFrom > data.validTo) {
            Common.showToast("Valid Till Date must be greater");
            return;
        }


        if (d.isClubSpecific && !d.clubId) {
            Common.showToast("Club Is Required when Club Specific");
            return;
        }

        if (d.isPlayerSpecific && !d.playerId) {
            Common.showToast("Player is required when Player Specific");
            return;
        }

        let response = await Common.ApiService.getInstance().request(
            "AddPromoCode",
            data,
            "POST"
        );
        action.setSubmitting(false);

        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/PromoCodes");
            }, 1000);
        }
    };

    const resetFields = (value, formikProps) => {
        if (+value == PromoCodeDiscountTypeEnum.Fixed_Amount) {
            //formikProps.setFieldValue("minOrderAmount", "");
            formikProps.setFieldValue("maxDiscount", "");
        }
        if (+value == PromoCodeDiscountTypeEnum.Percentage) {
            formikProps.setFieldValue("discountValue", "");
        }
    };


    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="space-y-6 mb-5">
                            <Card title={"Create Promo Code "}>
                                <div className="md:grid md:grid-cols-10 gap-4 p-5" >
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"code"}
                                            placeholder={"Code"}
                                            label={"Code"}
                                            maxLength="30"
                                            disabled={id}
                                        />
                                    </div>
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"validFrom"}
                                            label={"Valid From"}
                                            type={"datetime-local"}
                                            min={Common.Utility.todayDatetime}
                                            disabled={id}
                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">

                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"validTo"}
                                            label={"Valid To"}
                                            type={"datetime-local"}
                                            disabled={id}
                                            min={
                                                formikProps.values.validFrom ||
                                                Common.Utility.todayDatetime
                                            }
                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"discountType"}
                                            disabled={id}
                                            data={[
                                                { id: PromoCodeDiscountTypeEnum.Percentage, name: "Percentage" },
                                                { id: PromoCodeDiscountTypeEnum.Fixed_Amount, name: "Fixed Amount" },
                                            ]}
                                            placeholder={"Select Type"}
                                            label={"Discount Type"}
                                            onChange={(e) => {
                                                resetFields(e, formikProps);
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"discountValue"}
                                            disabled={id}
                                            placeholder={"Enter Discount Value"}
                                            label={"Discount Value"}
                                            type={"number"}
                                            max={IntMax}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("discountValue", IntMinLevel);
                                                } else if (formikProps.values.discountType == PromoCodeDiscountTypeEnum.Percentage && value > IntCourtPriceMin) {
                                                    Common.showToast(
                                                        `Discount value cannot be greater than ${IntCourtPriceMin}`
                                                    );
                                                    formikProps.setFieldValue("discountValue", "");
                                                } else if (formikProps.values.discountType != PromoCodeDiscountTypeEnum.Percentage && value > IntMax) {
                                                    Common.showToast(
                                                        `Discount value cannot be greater than ${IntMax}`
                                                    );
                                                    formikProps.setFieldValue("discountValue", "");
                                                } else {
                                                    formikProps.setFieldValue("discountValue", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            disabled={id}
                                            name={"minOrderAmount"}
                                            placeholder={"Enter Min Order Amount"}
                                            label={"Min Order Amount"}
                                            type={"number"}
                                            max={IntMax}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("minOrderAmount", IntMinLevel);
                                                } else if (value > IntMax) {
                                                    Common.showToast(
                                                        `Min Order Amount value cannot be greater than ${IntMax}`
                                                    );
                                                    formikProps.setFieldValue("minOrderAmount", "");
                                                } else {
                                                    formikProps.setFieldValue("minOrderAmount", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    {(formikProps.values.discountType == PromoCodeDiscountTypeEnum.Percentage) && (

                                        <div className="flex flex-col col-span-2 gap-2">
                                            <FormikInput
                                                formikProps={formikProps}
                                                disabled={id}
                                                name={"maxDiscount"}
                                                placeholder={"Enter Max Discount "}
                                                label={"Max Discount"}
                                                type={"number"}
                                                max={IntMax}
                                                min={IntMinLevel}
                                                onChange={(value) => {
                                                    if (value < IntMinLevel && value != "") {
                                                        formikProps.setFieldValue("maxDiscount", IntMinLevel);
                                                    } else if (value > IntMax) {
                                                        Common.showToast(
                                                            `Max Discount cannot be greater than ${IntMax}`
                                                        );
                                                        formikProps.setFieldValue("maxDiscount", "");
                                                    } else {
                                                        formikProps.setFieldValue("maxDiscount", value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            disabled={id}
                                            name={"usageLimit"}
                                            placeholder={"Enter Limit "}
                                            label={"Limit"}
                                            type={"number"}
                                            max={IntMax}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("usageLimit", IntMinLevel);
                                                } else if (value > IntMax) {
                                                    Common.showToast(
                                                        `Limit cannot be greater than ${IntMax}`
                                                    );
                                                    formikProps.setFieldValue("usageLimit", "");
                                                } else {
                                                    formikProps.setFieldValue("usageLimit", value);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"userUsageLimit"}
                                            disabled={id}
                                            placeholder={"Enter User Limit "}
                                            label={"User Limit"}
                                            type={"number"}
                                            max={IntMax}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("userUsageLimit", IntMinLevel);
                                                }
                                                else if (formikProps.values.usageLimit != "" && value > formikProps.values.usageLimit) {
                                                    Common.showToast(
                                                        `User Limit cannot be greater than Limit`
                                                    );
                                                    formikProps.setFieldValue("userUsageLimit", "");
                                                } else if (value > IntMax) {
                                                    Common.showToast(
                                                        `User Limit cannot be greater than ${IntMax}`
                                                    );
                                                    formikProps.setFieldValue("userUsageLimit", "");
                                                } else {
                                                    formikProps.setFieldValue("userUsageLimit", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    {(roleId && roleId == RoleEnum.Super_Admin) && (
                                        <>

                                            <div className="flex flex-col col-span-2 gap-2">
                                                <FormikSwitch
                                                    formikProps={formikProps}
                                                    disabled={id}
                                                    name={"isFirstTimeOnly"}
                                                    label={"Is First Time Only"}
                                                    style={{ marginTop: '7px' }}
                                                />
                                            </div>

                                            <div className="flex flex-col col-span-2 gap-1">
                                                <FormikSwitch
                                                    formikProps={formikProps}
                                                    disabled={id}
                                                    name={"isClubSpecific"}
                                                    label={"Is Club Specific"}
                                                    style={{ marginTop: '7px' }}
                                                    onChange={(e) => {
                                                        if (e == false)
                                                            formikProps.setFieldValue("clubId", "");
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className="flex flex-col col-span-2 gap-1">
                                                <FormikSelect
                                                    formikProps={formikProps}
                                                    disabled={!formikProps.values.isClubSpecific || id}
                                                    name={"clubId"}
                                                    data={clubs}
                                                    placeholder={"Select Club"}
                                                    label={"Club"}
                                                />
                                            </div>

                                        </>
                                    )}

                                    <div className="flex flex-col col-span-2 gap-1">
                                        <FormikSwitch
                                            formikProps={formikProps}
                                            disabled={id}
                                            name={"isPlayerSpecific"}
                                            label={"Player Specific"}
                                            style={{ marginTop: '7px' }}
                                            onChange={(e) => {
                                                if (e == false)
                                                    formikProps.setFieldValue("phone", "");
                                                formikProps.setFieldValue("playerName", "");
                                            }
                                            }

                                        />
                                    </div>

                                    {false && (
                                        <div className="flex flex-col col-span-2 gap-1">
                                            <FormikInput
                                                formikProps={formikProps}
                                                name={"playerId"}
                                                label={""}
                                                type={"number"}
                                                disabled={true}

                                            />
                                        </div>
                                    )}
                                    <div className="flex flex-col col-span-2 gap-1">
                                        {/*<FormikInput*/}
                                        {/*    formikProps={formikProps}*/}
                                        {/*    disabled={!formikProps.values.isPlayerSpecific || id}*/}
                                        {/*    name={"phone"}*/}
                                        {/*    label={"Customer Phone #"}*/}
                                        {/*    placeholder={"308-1234567"}*/}
                                        {/*    maxLength={30}*/}
                                        {/*    isPhoneNumber={true}*/}
                                        {/*    onBlur={(e) =>*/}
                                        {/*        handlePhoneBlur(e.target.value, formikProps)*/}
                                        {/*    }*/}
                                        {/*    onKeyDown={(e) => {*/}
                                        {/*        if (e.key === "Enter") {*/}
                                        {/*            e.preventDefault();*/}
                                        {/*            handlePhoneBlur(e.target.value, formikProps);*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*/>*/}

                                        <PhoneInputField
                                            formikProps={formikProps}
                                            disabled={!formikProps.values.isPlayerSpecific || id}
                                            maxLength={30}
                                            onBlur={(e) =>
                                                handlePhoneBlur(e.target.value, formikProps)
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    handlePhoneBlur(e.target.value, formikProps);
                                                }
                                            }}


                                        />

                                    </div>

                                    <div className="flex flex-col col-span-2 gap-1">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"playerName"}
                                            label={"Player Name"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>

                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
