import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useQueryParams } from "../../../contexts/queryParamsContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ClubPaymentLogTypeEnum,
    respEnum,
    IntMinLevel,
    IntMax,
    PaymentStatusEnum,
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikSearchSelect,
    FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";
import {
    BreadcrumbItem,
    Breadcrumbs,
} from "../../../components/ui/breadcrumbs";

const formValidations = yup.object({
    playerId: yup.number().required("Customer is Required"),
    adjustAmount: yup.number().required("Adjust Amount is required").min(IntMinLevel),
});

export default function AddAdjustment() {
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const [customerList, setCustomerList] = useState([]);
    const [bookingList, setBookingList] = useState([]);
    const [id, setId] = useState(null);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const { params, setParams } = useQueryParams();
    var remainingAdjustAmounts = 0;

    const formInitialValue = {
        bookings: bookingList.map((booking) => ({
            id: booking.id,
            isSelected: false,
            amount: null,
        })),
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
            GetDetails(fetchedId);
        }
    }, []);

    useEffect(() => {
        getCustomers();
        Common.delay();
    }, []);

    const getCustomers = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "ClubPlayersddl",
                {},
                "Get",
                false
            );
            const customerData = response?.data ?? [];

            const updatedCustomerList = [
                { id: null, name: "Select Customer", phone: "" },
                ...customerData.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                })),
            ];
            setCustomerList(updatedCustomerList);

            setTimeout(res, 1000);
        });
    };

    


    const getCustomerBooking = async (playerId) => {
        if (!playerId) {
            setBookingList([]);
            formikRef.current.setFieldValue("adjustAmount", "");
            formikRef.current.setFieldValue("balance", "");
            formikRef.current.setFieldValue("pendingAmount", "");
            formikRef.current.setFieldValue("playerBalAfterAdjustment", "");
            formikRef.current.values.bookings.forEach((_, index) => {
                formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
                formikRef.current.setFieldValue(`bookings[${index}].isSelected`, false);
            });
            return;

        }

        let response = await Common.ApiService.getInstance().request(
            `GetCustomerUnpaidBooking?playerId=${playerId}`
        );
        debugger
        const data = response?.data ?? {};
        const balance = data?.balance ?? 0;
        const pendingAmount = data?.pendingAmount ?? 0;
        const bookings = Array.isArray(data?.bookings) ? data.bookings : [];
        if (formikRef.current) {
            formikRef.current.setFieldValue("balance", balance);
            formikRef.current.setFieldValue("adjustAmount", "");
            formikRef.current.setFieldValue("pendingAmount", pendingAmount);
            remainingAdjustAmounts = 0;
            bookings.forEach((booking, index) => {
                formikRef.current.setFieldValue(`bookings[${index}].isSelected`, true);
            });
        }

        setBookingList(bookings);
        setSelectAllChecked(true);

    };

    const handleAdjust = async (formikProps) => {
        const { values } = formikProps;
        let adjustAmount = parseInt(values.adjustAmount, 10) || 0;
        remainingAdjustAmounts = adjustAmount;

        if (adjustAmount <= 0) {
            Common.showToast("Adjust Amount should be greater than 0");
            return
        }

        const selectedCount = values.bookings?.filter((b) => b && b.isSelected)?.length || 0;
        if (selectedCount === 0) {
            Common.showToast("Please select at least one booking to adjust the amount.");
            return;
        }

        bookingList.forEach((booking, index) => {
            if (!booking) {
                return;
            }

            if (remainingAdjustAmounts === 0) {
                formikProps.setFieldValue(`bookings[${index}].isSelected`, false);
                return;
            }
            const isSelected = values.bookings && values.bookings[index]?.isSelected;
            const remainingAmount = booking.remainingAmount || 0;

            if (isSelected && remainingAdjustAmounts > 0) {
                const amountToAdjust = Math.min(remainingAdjustAmounts, remainingAmount);
                remainingAdjustAmounts -= amountToAdjust;
                formikProps.setFieldValue(`bookings[${index}].amount`, amountToAdjust);
                formikProps.setFieldValue(`bookings[${index}].id`, booking.id);
            }

        });

        if (remainingAdjustAmounts > 0) {
            Common.showToast(`Amount Not completly adjusted. Remaining amount: ${remainingAdjustAmounts}`);
        }
        else {
            setSelectAllChecked(false);
        }
    };

    const GetDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `Getpayment?id=${id}`
        );

        let d = response?.data?.[0];
        if (formikRef.current) {
            formikRef.current.setValues(d);
        }
    };

    const onSubmitData = async (d, action) => {
        
        const clubAdjustmentBookings = d.bookings
            .filter((b) => b && b.amount && +b.amount > 0 && b.isSelected === true)  // Use isSelected instead of isChecked
            .map((b) => ({
                bookingId: +b.id,         
                adjustAmount: +b.amount, 
            }));


        debugger
        let data = {
            ...d,
            id,
            playerBal: +d.balance,
            playerBalAfterAdjustment: +d.playerBalAfterAdjustment,
            adjustAmount: +d.adjustAmount,
            clubAdjustmentBookings,
        };

        if (data.adjustAmount <= 0) {
            Common.showToast(`Adjustment Amount Should be Greater than 0`);
            return;
        }

        if (data.clubAdjustmentBookings.length == 0) {
            Common.showToast(`Their should be atleast one booking for adjustment`);
            return;
        }
        const totalAdjustAmount = clubAdjustmentBookings.reduce((sum, booking) => sum + booking.adjustAmount, 0);
        if (totalAdjustAmount !== +d.adjustAmount) {
            Common.showToast(`The sum of the booking amounts ${totalAdjustAmount} should be equal to the total adjustment amount ${d.adjustAmount}.`);
            return;
        }

        //if (remainingAdjustAmounts < 0) {
        //    Common.showToast(`Remaining amount is less than zero`);
        //    return;
        //}

        let response = await Common.ApiService.getInstance().request(
            "AdjustBookingPayments",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/adjustments");
            }, 1000);
        }
    };


    const calculateRemainingAdjustAmounts = (index) => {
        debugger
        if (!formikRef.current) return;

        const { bookings, adjustAmount } = formikRef.current.values;

        let totalAmount = 0;
        bookings.forEach((booking) => {
            if (booking && booking.amount) {
                totalAmount += +booking.amount;
            }
        });
        //let adjAmounts = remainingAdjustAmounts;
        //adjAmounts = adjustAmount - totalAmount;
        //if (adjAmounts < 0) {
        //    remainingAdjustAmounts = adjustAmount - totalAmount;
        //    Common.showToast(`Amount Cannot be greater than remaining amount. Remaining amount: ${remainingAdjustAmounts}`);
        //    formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
        //    return;
        //}

        //else if (remainingAdjustAmounts == 0) {
        //    Common.showToast(`Amount completly adjusted. Remaining amount: ${remainingAdjustAmounts}`);
        //}
        //remainingAdjustAmounts = adjAmounts;


        remainingAdjustAmounts = adjustAmount - totalAmount;
    };


    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setSelectAllChecked(isChecked);
        
        bookingList.forEach((booking, index) => {
            if (booking.remainingAmount > 0) {
                formikRef.current.setFieldValue(`bookings[${index}].isSelected`, isChecked);
            }
            if (!isChecked) {
                formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
            }
        });
    };

    const ShowBookingTable = ({ bookingList, formikProps }) => {
        //const handleSelectAll = (e) => {
        //    const isChecked = e.target.checked;
        //    bookingList.forEach((booking, index) => {
        //        if (booking.remainingAmount > 0) {
        //            formikProps.setFieldValue(`bookings[${index}].isSelected`, isChecked);
        //        }
        //    });
        //};

        return (
            <div className="mt-5">
                <div
                    className="overflow-y-auto border border-gray-300"
                    style={{ maxHeight: "400px" }}
                >
                    <table className="table-auto border-collapse border border-gray-300 w-full">
                        <thead>
                            <tr className="bg-gray-200">
                                <th hidden>Booking ID</th>

                                <th className="border border-gray-300 px-2 py-1 text-center w-1/14">
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={selectAllChecked}
                                    />
                                </th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-3/14">Booking Date</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-3/14">Time</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-2/14">Court</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-2/14">Payment Status</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-1/14">Total Payment</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-1/14">Remaining Amount</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-2/14">Adjust Amount</th>
                                <th className="border border-gray-300 px-2 py-1 text-center w-1/14"></th>
                            </tr>

                        </thead>
                        <tbody>
                            {bookingList.length > 0 ? (
                                bookingList.map((booking, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        

                                        {/*<td className="text-center align-middle p-2">*/}
                                        {/*    <div*/}
                                        {/*        className="inline-flex items-center justify-center w-10 h-10 border border-black-500 rounded-md hover:bg-blue-100 cursor-pointer"*/}
                                        {/*        title="View Details"*/}
                                        {/*        style={{ margin: '4px' }} // Add space around the box*/}
                                        {/*        onClick={() => {*/}
                                        {/*            setParams({*/}
                                        {/*                ...params,*/}
                                        {/*                bookingId: booking.id,*/}
                                        {/*            });*/}
                                        {/*            navigate("/app/bookingDetails");*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        <Icon*/}
                                        {/*            icon="heroicons:eye"*/}
                                        {/*            className="text-blue-500 text-xl"*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}




                                        <td className="border border-gray-300 px-4 py-2" hidden>
                                            <input
                                                type="hidden"
                                                name={`bookings[${index}].id`}
                                                value={booking.id}
                                            />
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            {booking.remainingAmount > 0 ? (
                                                <input
                                                    type="checkbox"
                                                    name={`bookings[${index}].isSelected`}
                                                    checked={
                                                        formikProps.values.bookings &&
                                                        formikProps.values.bookings[index]?.isSelected
                                                    }
                                                    onChange={(e) => {
                                                        formikProps.setFieldValue(
                                                            `bookings[${index}].isSelected`,
                                                            e.target.checked
                                                        );

                                                        // Clear the amount if unchecked
                                                        if (!e.target.checked) {
                                                            formikProps.setFieldValue(`bookings[${index}].amount`, "");
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <span></span>
                                            )}

                                        </td>
                                        {/*<td className="border border-gray-300 px-4 py-2">*/}
                                        {/*    <div>*/}
                                        {/*        <strong>Date:</strong> {Common.Utility.ConvertDate(booking.bookingDate)}*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <strong>Time:</strong> {booking.bookingDetail}*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}

                                        <td className="border border-gray-300 px-4 py-2">
                                            {Common.Utility.ConvertDate(booking.bookingDate)}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            {booking.bookingDetail}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            {booking.courtName}
                                        </td>

                                        <td className="border border-gray-300 px-4 py-2">
                                              {Common.Utility.renderBadge(
                                                  booking.paymentStatus,
                                                  Common.Utility.getKeyByValue(PaymentStatusEnum, booking.paymentStatus)
                                                )}
                                        </td>

                                        <td className="border border-gray-300 px-4 py-2">
                                            {booking.total}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            {booking.remainingAmount}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">
                                            <FormikInput
                                                formikProps={formikProps}
                                                name={`bookings[${index}].amount`}  // Correct field name
                                                placeholder="Enter Amount"
                                                type="number"
                                                max={formikProps.values.adjustAmount}
                                                min={IntMinLevel}
                                                disabled={booking.remainingAmount === 0 || formikProps.values.adjustAmount <= 0 || !formikProps.values.bookings[index]?.isSelected}
                                                value={formikProps.values.bookings[index]?.amount ?? ""}
                                                onChange={(value) => {
                                                    if (value < IntMinLevel && value !== "") {
                                                        formikProps.setFieldValue(`bookings[${index}].amount`, "");
                                                    } else if (value > remainingAdjustAmounts && value > booking.remainingAmount) {
                                                        Common.showToast(`Amount cannot be greater than Remaining Amount`);
                                                        formikProps.setFieldValue(`bookings[${index}].amount`, "");
                                                    } else {
                                                        formikProps.setFieldValue(`bookings[${index}].amount`, value);
                                                    }
                                                    calculateRemainingAdjustAmounts(index);
                                                    formikProps.setFieldValue(`bookings[${index}].id`, booking.id);
                                                    
                                                }}
                                                onKeyDown={(event) => {
                                                    calculateRemainingAdjustAmounts(index);
                                                }}
                                                onBlur={() => calculateRemainingAdjustAmounts(index)}
                                            />
                                        </td>
                                        {/* circle*/}
                                        <td className="text-center align-middle p-2">
                                            <div
                                                className="inline-flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full shadow hover:bg-blue-200 cursor-pointer"
                                                title="View Details"
                                                style={{ margin: '4px' }} 
                                                onClick={() => {
                                                    setParams({
                                                        ...params,
                                                        bookingId: booking.id,
                                                    });
                                                    navigate("/app/bookingDetails");
                                                }}
                                            >
                                                <Icon
                                                    icon="heroicons:eye"
                                                    className="text-blue-500 text-xl"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        className="border border-gray-300 px-4 py-2 text-center"
                                        colSpan="5"
                                    >
                                        No bookings available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };



    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="flex flex-wrap mb-3">
                            <div className="text-xl font-medium text-default-900 flex-1">
                            </div>
                            <div className="flex-none">
                                <Breadcrumbs>
                                    <BreadcrumbItem
                                        onClick={() =>
                                            navigate("/app/adjustments")
                                        }
                                    >
                                        Adjustments
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>Adjustment Bookings</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div className="space-y-6 mb-5">
                           
                        <Card title={id ? " Update Adjustment" : "Adjust Bookings"}>
                            
    <div className="flex flex-row gap-4 p-5 items-center">
        <div className="flex-grow">
            <FormikSearchSelect
                formikProps={formikProps}
                name={"playerId"}
                disabled={id}
                data={customerList}
                placeholder={"Select Customer"}
                className="w-full"
                label={"Customer"}
                onChange={(e) => {
                    getCustomerBooking(e);
                }}
            />
        </div>

        <div>
            <FormikInput
                formikProps={formikProps}
                name={"balance"}
                placeholder={"Current Balance"}
                label={"Current Balance"}
                disabled={true}
            />
        </div>

        <div>
            <FormikInput
                formikProps={formikProps}
                name={"pendingAmount"}
                placeholder={"Unsettled Balance"}
                label={"Unsettled Balance"}
                disabled={true}
            />
        </div>

        <div>
            <FormikInput
                formikProps={formikProps}
                name={"adjustAmount"}
                placeholder={"Enter Adjust Amount"}
                label={"Adjust Amount"}
                type={"number"}
                max={formikProps.values.balance}
                min={IntMinLevel}
                disabled={!formikProps.values.playerId}
                className="w-40" 
                onChange={(value) => {
                    if (value < IntMinLevel && value != "") {
                        formikProps.setFieldValue("adjustAmount", "");
                        formikProps.setFieldValue("playerBalAfterAdjustment", "");
                    } else if (value > formikProps.values.balance) {
                        Common.showToast(
                            `Adjust Amount cannot be greater than Balance`
                        );
                        formikProps.setFieldValue("adjustAmount", "");
                        formikProps.setFieldValue("playerBalAfterAdjustment", "");
                    } else {
                        formikProps.setFieldValue("adjustAmount", value);
                        const balAfterAdjust = formikProps.values.balance
                            ? formikProps.values.balance - value
                            : 0;
                        formikProps.setFieldValue("playerBalAfterAdjustment", balAfterAdjust);
                    }
                    formikProps.values.bookings.forEach((_, index) => {
                        formikProps.setFieldValue(`bookings[${index}].amount`, "");
                    });
                    remainingAdjustAmounts = value;
                }}
            />
        </div>

        <div>
            <FormikInput
                formikProps={formikProps}
                name={"playerBalAfterAdjustment"}
                placeholder={"Customer Balance After Adjustment"}
                label={"Customer Balance After Adjustment"}
                disabled={true}
            />
        </div>

        {formikProps.values.playerId && formikProps.values.adjustAmount > 0 && (
            <div>
                <Button
                    onClick={(e) => {
                        handleAdjust(formikProps);
                    }}
                    type="button"
                    className="btn-sm mt-5"
                >
                    Adjust
                </Button>
            </div>
        )}
    </div>
</Card>
                        </div>

                        {bookingList.length > 0 && (
                            <ShowBookingTable bookingList={bookingList} formikProps={formikProps} />
                        )}

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>

                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
