import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../../contexts/queryParamsContext";
import {
    PromoCodeDiscountTypeEnum,
    respEnum,
    ViewDateTimeFormat,
} from "../../constants/appConstant";

const PromoCodes = () => {
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const { setId } = useQueryParams();

    useEffect(() => {
        getData(1);
    }, []);

    const getData = async (
        pageNumber,
        searchTerm = "",
        recordsToDisplay = null
    ) => {

        const response = await Common.ApiService.getInstance().request(
            "GetPromoCodes",
            {
                pageNumber: pageNumber,
                q: searchTerm,
                recordsToDisplay,
            },
            "Post"
        );
        if (response?.data) {
            let obj = {
                ...response.data[0],
                data: response.data[0].data.map((item) => ({
                    ...item,
                    validFrom: `${moment(item.validFrom).format(ViewDateTimeFormat)}`,
                    validTo: `${moment(item.validTo).format(ViewDateTimeFormat)}`,
                    isFirstTimeOnly: item.isFirstTimeOnly ? "Yes" : "No",
                    discountValue: item.discountType == PromoCodeDiscountTypeEnum.Percentage ? `${item.discountValue} %` : item.discountValue ,
                    maxDiscount: item.maxDiscount ?? "_",
                    minOrderAmount: item.minOrderAmount ?? "_",
                    usageLimit: item.usageLimit ?? "_",
                    userUsageLimit: item.userUsageLimit ?? "_",
                    discountTypeId: item.discountType,
                    discountType: Common.Utility.getKeyByValue(
                        PromoCodeDiscountTypeEnum,
                        item.discountType
                    ),
                })),
            };
            setData(obj);
        }
    };

    const _handleOnChange = async (obj) => {
        debugger
        let resp = await Common.ApiService.getInstance().request(
            `ActiveDeactivePromoCode?id=${obj.id}&isActive=${!obj.isActive}`,
            {},
            "POST"
        );
        if (resp.status == respEnum.Success) {
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                //setData(
                //    data.data.map((item) =>
                //        item.id == obj.id ? { ...item, isActive: !item.isActive } : item
                //    )
                //);
              getData(1);
            }, 300);
        }
    };

  const _thead = [
      { title: "Id", type: "number", isVisible: false, key: "id" },
      { title: "Code", type: "text", isVisible: true, key: "code" },
      { title: "Valid From", type: "text", isVisible: true, key: "validFrom" },
      { title: "Valid To", type: "text", isVisible: true, key: "validTo" },
      { title: "Discount Type", type: "text", isVisible: true, key: "discountType" },
      { title: "Discount Value", type: "text", isVisible: true, key: "discountValue" },
      //{ title: "Min Order Amount", type: "text", isVisible: true, key: "minOrderAmount" },
      //{ title: "Max Discount", type: "text", isVisible: true, key: "maxDiscount" },
      { title: "Limit", type: "text", isVisible: true, key: "usageLimit" },
      { title: "User Limit", type: "text", isVisible: true, key: "userUsageLimit" },
      { title: "Total Redeem", type: "text", isVisible: true, key: "totalRedeemed" },
      //{ title: "Is First Time Only", type: "text", isVisible: true, key: "isFirstTimeOnly" },
      { title: "Is Active", type: "toggle", isVisible: true, key: "isActive", onChanged: (item) => _handleOnChange(item), },
  ];


  //const actions = [
  //  {
  //          type: "edit",
  //          onClick: (val) => {
  //            global.log(val.id)
  //              navigate(`/app/addPromoCodes?id=${val.id}`, {});
  //        },
  //        visibleProp: "canEdit",
  //        visibleValue: true
  //  }
  //];

    const actions = [
        {
            type: "view",
            onClick: (val) => {
                setId(val.id);
                navigate("/app/promocodesdetail");
            },
        },
    ];


  //const _addUpdateTrigger = () => (
  //  <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
  //    <Icon icon="heroicons:pencil" className="h-4 w-4" />
  //  </Button>
  //);


  const _addBtn = () => (
      <Button onClick={() => navigate("/app/AddPromoCodes")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add
    </Button>
  );

    return (
        <>
            {data?.data && (
                <AdvanceTable
                    title={"Promo Codes"}
                    columns={_thead.filter((item) => item.isVisible)}
                    data={data}
                    getData={getData}
                    addButton={_addBtn}
                    showPagination
                    showAddButton
                    actionButtons={actions}
                    //updateTrigger={_addUpdateTrigger} // Example function
                    //showExportoptions
                />
            )}
        </>
    );
};

export default PromoCodes;
