import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript, Autocomplete } from "@react-google-maps/api";

// Move libraries outside of the component
const libraries = ["places"];

const GoogleMapCustom = ({
    defaultLat,
    defaultLng,
    onLocationChange,
    isReinit,
    pinLocationName: initialPinLocationName, // Default location name passed as prop
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAE6MQgx8MwLZCVOp7Qb11j4zG6hd9YikM",
        libraries, // Use the static libraries array
    });

    const [markerPosition, setMarkerPosition] = useState({
        lat: defaultLat,
        lng: defaultLng,
        pinLocationName: initialPinLocationName || "",
    });

    const [inputValue, setInputValue] = useState(initialPinLocationName || ""); // Input value state
    const [mapCenter, setMapCenter] = useState({ lat: defaultLat, lng: defaultLng });
    const autocompleteRef = useRef(null);

    // Sync markerPosition changes with parent
    useEffect(() => {
        if (onLocationChange) {
            onLocationChange(markerPosition);
        }
    }, [markerPosition, onLocationChange]);

    // Update marker position and location name when marker is dragged
    const handleMarkerDragEnd = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();

        // Reverse geocoding to get location name
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            { location: { lat: newLat, lng: newLng } },
            (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        const newPosition = {
                            lat: newLat,
                            lng: newLng,
                            pinLocationName: results[0].formatted_address,
                        };
                        setMarkerPosition(newPosition);
                        setInputValue(results[0].formatted_address || ""); // Update input value
                    }
                } else {
                    console.error("Geocoding failed:", status);
                }
            }
        );
    }, []);

    // Handle location selection via Autocomplete
    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const newCenter = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                pinLocationName: place.formatted_address || place.name,
            };
            setMapCenter(newCenter);
            setMarkerPosition(newCenter);
            setInputValue(newCenter.pinLocationName || ""); // Update input value
        }
    };

    // Handle manual input change
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setMarkerPosition((prev) => ({
            ...prev,
            pinLocationName: event.target.value,
        }));
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={handlePlaceChanged}
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search for a location"
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                    }}
                />
            </Autocomplete>

            <GoogleMap
                mapContainerStyle={{
                    width: "100%",
                    height: "400px",
                }}
                center={mapCenter}
                zoom={14}
            >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
            </GoogleMap>
        </div>
    );
};

export default GoogleMapCustom;
