import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";
import { SportFeatureTypeEnum } from "../../constants/appConstant";
import { useQueryParams } from "../../contexts/queryParamsContext";

const Courts = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { params, setParams, resetParams } = useQueryParams();

  useEffect(() => {
    getData(1);
  }, []);

    const getData = async (pageNumber, searchTerm = "", recordsToDisplay = null ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetClubCourts",
      {
        pageNumber: pageNumber,
          q: searchTerm,
          recordsToDisplay,
        sportId: null,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          isactive: item.isactive ? "Yes" : "No",
          isBookableOnline: item.isBookableOnline ? "Yes" : "No",
          features: item.features
            ? item.features
                .filter(
                  (feature) => feature.type === SportFeatureTypeEnum.Feature
                )
                .map((feature) => feature.sportFeatureName)
                .join(", ")
            : "",
        })),
      };

      setData(obj);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        setParams({ ...params, courtId: val.id });
        navigate(`/app/addCourt`);
      },
    },
  ];

  let columns = [
    { title: "Court Name", type: "text", key: "name" },
    { title: "Sport", type: "text", key: "sportName" },
    { title: "Is Active", type: "text", key: "isactive" },
    { title: "Online Booking", type: "text", key: "isBookableOnline" },
    { title: "Features", type: "text", key: "features" },
  ];

  const _addBtn = () => (
    <Button
      onClick={() => {
        resetParams();
        navigate("/app/addCourt");
      }}
      className="h-8 text-xs"
      size="xs"
      variant=""
    >
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Court
    </Button>
  );

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={"Courts"}
          columns={columns}
          data={data}
          getData={getData}
          addButton={_addBtn}
          showPagination
          showAddButton
          actionButtons={actions}
          updateTrigger={_addUpdateTrigger} // Example function
        />
      )}
    </>
  );
};

export default Courts;
