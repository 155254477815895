"use client";

import React, { useEffect, useRef, useState } from "react";
import animationData from "../../assets/json/lotties/failed.json";

// Mapping of error codes to their descriptions
const errorMessages = {
  "00": "Processed OK",
  "002": "Time Out",
  97: "Insufficient balance to proceed",
  106: "Transaction limit exceeded, please contact your bank",
  "03": "Inactive account entered",
  104: "Incorrect details entered",
  55: "Invalid OTP/PIN entered",
  54: "Card expired",
  13: "Invalid amount entered",
  126: "Provided account details are invalid",
  75: "Maximum PIN retries exceeded",
  14: "Inactive card number entered",
  15: "Inactive card number entered",
  42: "Invalid CNIC entered",
  423: "Unable to process your request at the moment, please try again later",
  41: "Details entered are mismatched",
  801: "OTP sent to your email address",
  802: "OTP could not be sent, please try again later",
  803: "OTP sent to your mobile number",
  805: "OTP verified",
  806: "OTP could not be verified",
  807: "Too many attempts, please try again later in a few minutes",
  808: "Passwords do not match",
  809: "Invalid password",
  810: "Password could not be changed",
  811: "Password changed successfully",
  812: "Request could not be validated, please try again",
  813: "Email address already registered",
  9000: "FRMS rejected",
  9010: "FRMS error",
  308: "Provided account details are invalid",
  600: "OTP already expired",
  309: "Invalid OTP length entered",
  853: "Provided account details are invalid",
  "04": "Closed account entered",
  537: "Dormant account entered",
  359: "Blocked account",
  880: "Transaction declined due to non-activation of local ecommerce session, please call your bank",
  881: "Transaction declined due to insufficient funds, please call your bank for assistance",
  882: "Transaction declined due to consumption of daily local epayment transaction limit/count",
  883: "Transaction declined, please call your bank to activate local epayment service",
};

const PayementFailurePage = () => {
  const [errorCode, setErrorCode] = useState(null);
  const animationRef = useRef(null);

  // Function to get the error code from the URL
  const getErrorCode = () => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      return params.get("err_code");
    }
    return null;
  };

  useEffect(() => {
    setErrorCode(getErrorCode());

    // Load the Lottie animation
    const loadLottie = async () => {
      const lot = await import("lottie-web");
      lot.default.loadAnimation({
        loop: true,
        autoplay: true,
        animationData: animationData,
        container: animationRef.current,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
    };

    loadLottie();
  }, []);

  // Get the error message based on the error code
  const errorMessage =
    errorMessages[errorCode] || "An unknown error occurred. Please try again.";

  return (
    <div className="min-h-screen overflow-y-auto flex justify-center items-center p-10">
      <div className="flex flex-col items-center">
        <div className="text-center">
          <div>
            <div ref={animationRef}></div>
          </div>
          <div className="text-3xl md:text-4xl lg:text-5xl font-semibold text-destructive-900">
            Payment Failed
          </div>
          <div className="mt-3 text-default-600 text-sm md:text-base">
            {errorMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayementFailurePage;
