import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";
import {
    AppSearchSelect,
    AppInput,
} from "../../components/appComponents";
import { useQueryParams } from "../../contexts/queryParamsContext";

const Adjustment = () => {
    const [data, setData] = useState({});
    let [fromDate, setfromDate] = useState(null);
    let [tillDate, settillDate] = useState(null);
    const [playerId, setplayerId] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const { params, setParams } = useQueryParams();

  const navigate = useNavigate();

    useEffect(() => {
        getData(1);
        getCustomers();
    }, []);

    useEffect(() => {
        getData(1);
        getCustomers();
    }, [playerId, fromDate, tillDate]);

    const resetFilters = () => {
        setfromDate(null);
        settillDate(null);
        setplayerId(null);
    };

    const getData = async (
        pageNumber,
        searchTerm = "",
        recordsToDisplay = null
    ) => {
        debugger
        if (fromDate == "") { fromDate = null; }
        if (tillDate == "") { tillDate = null; }
        const response = await Common.ApiService.getInstance().request(
            "GetClubAdjustmentLogs",
            {
                pageNumber: pageNumber,
                q: searchTerm,
                isactive: true,
                recordsToDisplay,
                playerId,
                fromDate,
                tillDate,
                
            },
            "Post"
        );
        if (response?.data) {
            debugger
            let obj = {
                ...response.data[0],
                data: response.data[0].data.map((item) => ({
                    ...item,
                    afterAdjustment: item.playerBal - item.adjustAmount,
                    createdOn: Common.Utility.ConvertTime(item.createdOn),
                })),
            };

            setData(obj);
        }
    };


    const getCustomers = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "ClubPlayersddl",
                {},
                "Get",
                false
            );
            const customerData = response?.data ?? [];

            const updatedCustomerList = [
                { id: null, name: "Select Customer", phone: "" },
                ...customerData.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                })),
            ];
            setCustomerList(updatedCustomerList);

            setTimeout(res, 1000);
        });
    };


    const columns = [
      { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "Customer", type: "text", isVisible: true, key: "playerName" },
        { title: "Previous Balance", type: "text", isVisible: true, key: "playerBal" },
        { title: "Adjust Amount", type: "text", isVisible: true, key: "adjustAmount" },
        { title: "After Adjustment", type: "text", isVisible: true, key: "afterAdjustment" },
        { title: "Created On", type: "text", isVisible: true, key: "createdOn" },
    ];


    const actions = [
        {
            type: "view",
            onClick: (val) => {
                setParams({
                    ...params,
                    clubAdjustmentLogId: val.id,
                });
                navigate("/app/adjustmentlog");
            },
        },
    ];

  //const actions = [
  //  {
  //          type: "edit",
  //          onClick: (val) => {
  //            global.log(val.id)
  //            navigate(`/app/addAccount?id=${val.id}`, {});
  //        },
  //        visibleProp: "canEdit",
  //        visibleValue: true
  //  },
  //];



  //const _addUpdateTrigger = () => (
  //  <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
  //    <Icon icon="heroicons:pencil" className="h-4 w-4" />
  //  </Button>
  //);


        const _addBtn = () => (
            <Button onClick={() => navigate("/app/addadjustment")}>
            <span className="text-xl mr-1">
                <Icon icon="icon-park-outline:plus" />
            </span>
           Adjust Bookings
            </Button>
        );

    return (
        <>
            <div className="md:grid md:grid-cols-6 gap-3 ">

                <AppInput
                    type={"date"}
                    value={fromDate}
                    onChange={(e) => {
                        const selectedstartDate = e;
                        if (tillDate && selectedstartDate > tillDate) {
                            Common.showToast("Start Time must be lesser than End Time", "Error", "info");
                            setfromDate(null);
                        } else {
                            setfromDate(selectedstartDate);
                        }
                    }}
                    label="From"
                    className="mb-3 col-span-1 pl-6"
                />

                <AppInput
                    type={"date"}
                    value={tillDate}
                    onChange={(e) => {
                        const selectedEndDate = e;
                        if (selectedEndDate < fromDate) {
                            Common.showToast("End Time must be greater", "Error", "info");
                            settillDate("");
                        } else {
                            settillDate(selectedEndDate);
                        }
                    }}
                    label="Till"
                    className="mb-3 col-span-1"
                />

                <AppSearchSelect
                    value={playerId}
                    onChange={setplayerId}
                    placeholder={"Select Customer"}
                    label={"Customer"}
                    data={customerList}
                    className={"mb-3 col-span-1 "}
                />
               
                <Button className="mt-6 h-8" onClick={resetFilters}>
                    Reset Filters
                </Button>
            </div>

            {data?.data && (
                <AdvanceTable
                    title={"Booking Adjustments"}
                    columns={columns.filter((item) => item.isVisible)}
                    data={data}
                    getData={getData}
                    showPagination
                    showAddButton
                    actionButtons={actions}
                    addButton={_addBtn}
                />
            )}
        </>
    );
};

export default Adjustment;
