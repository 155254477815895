import React, { useEffect, useState } from "react";
import Card from "../../../components/ui/card-snippet";
import { useQueryParams } from "../../../contexts/queryParamsContext";
import { PromoCodeDiscountTypeEnum, respEnum, RoleEnum } from "../../../constants/appConstant";
import Common from "../../../helper/common";


export default function PromoCodesDetailView() {
    const [promoDetails, setPromoDetails] = useState(null);
    const { id, setId } = useQueryParams();
    const [roleId, setRoleId] = useState(null);

    useEffect(() => {
        (async () => {
            setRoleId(global.user.roleId);
        })();
    }, []);


    useEffect(() => {
        if (id) {
            fetchPromoCodeDetail();
        }

        return () => {
            setId(null);
        };
    }, [id]);

    const fetchPromoCodeDetail = async () => {
        const response = await Common.ApiService.getInstance().request(
            `GetPromoCodeDetail?id=${id}`
        );

        if (response.status === respEnum.Success && response?.data?.length > 0) {
            setPromoDetails(response.data[0]);
        }
    };

    if (!promoDetails) {
        return <p>Loading...</p>;
    }

    return (
        <div className="space-y-6 mb-5">
            <Card title={"Promo Code Details"}>
                <div className="grid md:grid-cols-2 gap-4 p-5">
                    <DetailField label="Code" value={promoDetails.code} />
                    <DetailField
                        label="Discount Type"
                        value={
                            promoDetails.discountType === PromoCodeDiscountTypeEnum.Percentage
                                ? "Percentage"
                                : "Fixed Amount"
                        }
                    />
                    <DetailField
                        label="Valid From"
                        value={Common.Utility.formatDateTime(promoDetails.validFrom)}
                    />
                    <DetailField
                        label="Valid To"
                        value={Common.Utility.formatDateTime(promoDetails.validTo)}
                    />
                    <DetailField
                        label="Discount Value"
                        value={promoDetails.discountValue}
                    />
                    <DetailField
                        label="Min Order Amount"
                        value={promoDetails.minOrderAmount}
                    />
                    {promoDetails.discountType === PromoCodeDiscountTypeEnum.Percentage && (
                        <DetailField
                            label="Max Discount"
                            value={promoDetails.maxDiscount}
                        />
                    )}
                    <DetailField
                        label="Usage Limit"
                        value={promoDetails.usageLimit}
                    />
                    <DetailField
                        label="User Usage Limit"
                        value={promoDetails.userUsageLimit}
                    />
                    {(roleId && roleId === RoleEnum.Super_Admin) && (
                        <>
                            <DetailField
                                label="Is First Time Only"
                                value={promoDetails.isFirstTimeOnly ? "Yes" : "No"}
                            />
                            <DetailField
                                label="Is Club Specific"
                                value={promoDetails.isClubSpecific ? "Yes" : "No"}
                            />
                            {promoDetails.isClubSpecific && (
                                <DetailField
                                    label="Club"
                                    value={promoDetails.clubName || "N/A"}
                                />
                            )}
                        </>
                    )}
                    <DetailField
                        label="Player Specific"
                        value={promoDetails.isPlayerSpecific ? "Yes" : "No"}
                    />
                    {promoDetails.isPlayerSpecific && (
                        <>
                            <DetailField
                                label="Customer Phone"
                                value={promoDetails.phone}
                            />
                            <DetailField
                                label="Player Name"
                                value={promoDetails.playerName}
                            />
                        </>
                    )}
                    <DetailField
                        label="Total Redeemed"
                        value={promoDetails.totalRedeemed}
                    />
                </div>
                <div className="grid md:grid-cols-1 gap-4 p-5">
                    <div>
                        <h5 className="text-lg font-semibold text-gray-900">Terms And Conditions</h5>
                        <ul className="list-disc list-inside space-y-2">
                            {promoDetails.termsAndConditions
                                .split('.')
                                .filter((point) => point.trim() !== '') 
                                .map((point, index) => (
                                    <li key={index} className="text-gray-700">{point.trim()}.</li>
                                ))}
                        </ul>
                    </div>
                </div>

            </Card>
        </div>
    );
}

const DetailField = ({ label, value }) => (
    <div className="flex flex-col gap-1">
        <span className="text-sm font-medium text-gray-500">{label}</span>
        <p className="text-lg font-semibold text-gray-900">{value || "-"}</p>
    </div>
);
