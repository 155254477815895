import { createBrowserRouter } from "react-router-dom";
import Sports from "../containers/sports";
import PayementSuccessPage from "../containers/paymentSuccess";
import LoginPage from "../containers/auth/login";
import ErrorPage from "../containers/Error";
import StartPage from "../containers";
import MainLayout from "../containers/mainWrapper";
import ClubBookings from "../containers/clubbookings";
import MasterFacilities from "../containers/clubSetup/masterFacilities";
import ClubCustomers from "../containers/clubCustomers";
import Clubs from "../containers/clubs";
import LevelThreshold from "../containers/setup/levelThresholds";
import Countries from "../containers/setup/countries";
import States from "../containers/setup/states";
import Cities from "../containers/setup/cities";
import Courts from "../containers/courts";
import AddCourt from "../containers/courts/addCourt";
import AddCourtHoliday from "../containers/courts/courtHolidays/addCourtHoliday";
import CourtHolidays from "../containers/courts/courtHolidays";
import Transactions from "../containers/transactions";
import TransactionLogs from "../containers/transactions/logs";
import BooingDetails from "../containers/clubbookings/bookingDetails/bookingDetails";
import Tournaments from "../containers/tournament";
import AddTournament from "../containers/tournament/addTournament";
import TournamentGrouping from "../containers/tournament/grouping";
import BookingCalendar from "../containers/clubbookings/calendar/calendar";
import AddSport from "../containers/sports/addSport";
import Scheduling from "../containers/tournament/scheduling/index";
import Dashboard from "../containers/dashboard";
import ClubSettings from "../containers/clubSettings";
import PromoCode from "../containers/promoCode";
import SportQuestions from "../containers/questions";
import AddQuestion from "../containers/questions/components/addQuestion";
import ClubDetails from "../containers/clubDetails";
import PayMethodAdminSetup from "../containers/paymethod/paymethodAdminSetup";
import AddAccount from "../containers/clubAccounts/addAccount";
import ClubAccount from "../containers/clubAccounts";
import Notifications from "../containers/notifications";
import AddNotifications from "../containers/notifications/components/addNotifications";
import ResourceCalendar from "../components/ui/bigcalendar";
import BookingCalendar2 from "../containers/bookingcalendar";
import Error404 from "../containers/auth/404";
import AddPromoCodes from "../containers/promoCodes/addPromoCodes";
import PromoCodesDetail from "../containers/promoCodes/promoCodesDetail";
import PromoCodes from "../containers/promoCodes";
import PayementFailurePage from "../containers/paymentFailed";

import AddClubHoliday from "../containers/clubs/clubHolidays/addClubHoliday";
import ClubHolidays from "../containers/clubs/clubHolidays";
import PaymentAccountBalance from "../containers/reports/paymentAccountBalance";
import Balance from "../containers/reports/balance";

import AddPayments from "../containers/payments/addPayments";
import Payments from "../containers/payments";
import AddAdjustment from "../containers/adjustment/addAdjustment";
import Adjustment from "../containers/adjustment";
import AdjustmentLog from "../containers/adjustment/adjustmentLog";

import POSSystem from "../containers/pos";


const errorElement = <ErrorPage />;
export const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
    errorElement,
  },
  {
    path: "/Login",
    element: <LoginPage />,
    errorElement,
  },
  {
    path: "/PaymentSuccess",
    element: <PayementSuccessPage />,
    errorElement,
  },
  {
    path: "/PaymentFailed",
    element: <PayementFailurePage />,
    errorElement,
  },
  // {
  //   path: "/PaymentSuccess",
  //   element: <PaymentFailed />,
  //   errorElement,
  // },

  {
    path: "/404",
    element: <Error404 />,
  },
  {
    // Authenticated routes are children of MainLayout, under /app
    path: "/app", // This is the base path for authenticated routes
    element: <MainLayout />, // The main layout wrapping authenticated routes
    errorElement,
    children: [
      {
        index: true,
        path: "sports",
        element: <Sports />,
      },
      {
        index: true,
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        index: true,
        path: "addSport",
        element: <AddSport />,
      },
      {
        path: "clubbookings",
        element: <ClubBookings />,
      },
      {
        path: "calendar",
        element: <BookingCalendar />,
      },
      {
        path: "bookingDetails",
        element: <BooingDetails />,
      },
      {
        path: "facilities",
        element: <MasterFacilities />,
      },
      {
        path: "clubcustomers",
        element: <ClubCustomers />,
      },
      {
        path: "clubs",
        element: <Clubs />,
      },
      {
        path: "levelThreshold",
        element: <LevelThreshold />,
      },
      {
        path: "countries",
        element: <Countries />,
      },
      {
        path: "states",
        element: <States />,
      },
      {
        path: "cities",
        element: <Cities />,
      },
      {
        path: "courts",
        element: <Courts />,
      },
      {
        path: "addCourt",
        element: <AddCourt />,
      },
      {
        path: "courHolidays",
        element: <CourtHolidays />,
      },
      {
        path: "addCourtHoliday",
        element: <AddCourtHoliday />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "transactionLogs",
        element: <TransactionLogs />,
      },
      {
        path: "tournaments",
        element: <Tournaments />,
      },
      {
        path: "addTournament",
        element: <AddTournament />,
      },
      {
        path: "tournamentGrouping",
        element: <TournamentGrouping />,
      },
      {
        path: "tournamentScheduling",
        element: <Scheduling />,
      },
      {
        path: "clubSetup",
        element: <ClubSettings />,
      },
      {
        path: "promoCode",
        element: <PromoCode />,
      },
      {
        path: "questions",
        element: <SportQuestions />,
      },
      {
        path: "addQuestion",
        element: <AddQuestion />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "clubDetails",
        element: <ClubDetails />,
      },
      {
        path: "adminPayMethod",
        element: <PayMethodAdminSetup />,
      },
      {
        path: "addAccount",
        element: <AddAccount />,
      },
      {
        path: "clubAccounts",
        element: <ClubAccount />,
      },
      {
        path: "bigCalendar",
        element: <ResourceCalendar />,
      },
      {
        path: "bookingcalendar",
        element: <BookingCalendar2 />,
      },

      {
        path: "addpromocodes",
        element: <AddPromoCodes />,
      },
      {
        path: "promocodes",
        element: <PromoCodes />,
      },
      {
        path: "addpromocodes",
        element: <AddPromoCodes />,
      },
      {
        path: "promocodes",
        element: <PromoCodes />,
      },
      {
        path: "promocodesdetail",
        element: <PromoCodesDetail />,
        },
        {
            path: "clubholidays",
            element: <ClubHolidays />,
        },
        {
            path: "addclubholiday",
            element: <AddClubHoliday />,
        },
        {
            path: "paymentaccountbalance",
            element: <PaymentAccountBalance />,
        },
        {
            path: "balance",
            element: <Balance />,
        },
        {
          path: "addNotification",
          element: <AddNotifications />,
      },

        {
            path: "addpayments",
            element: <AddPayments />,
        },

        {
            path: "payments",
            element: <Payments />,
          },

        {
            path: "addAdjustment",
            element: <AddAdjustment />,
        },

        {
            path: "adjustments",
            element: <Adjustment />,
        },

        {
            path: "adjustmentlog",
            element: <AdjustmentLog />,
        },

          {
            path: "pos",
            element: <POSSystem />,
          },

      // Add more authenticated routes here
    ],
  },
]);
