import React, { useEffect, useState } from "react";
import Card from "../../../components/ui/card-snippet";
import { useQueryParams } from "../../../contexts/queryParamsContext";
import { respEnum } from "../../../constants/appConstant";
import Common from "../../../helper/common";

export default function PaymentAccountBalance() {
    const [accountDetails, setAccountDetails] = useState([
        {
            account: "Cash",
            receive: 2000,
            pay: 500,
            refund: 100,
        },
        {
            account: "PayFast",
            receive: 1500,
            pay: 800,
            refund: 50,
        },
        {
            account: "Bank",
            receive: 3000,
            pay: 1200,
            refund: 200,
        },
        {
            account: "Other",
            receive: 1000,
            pay: 300,
            refund: 150,
        },
    ]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedAccount, setSelectedAccount] = useState("");
    const [totals, setTotals] = useState({ receive: 0, pay: 0, refund: 0 });
    const [showDetails, setShowDetails] = useState(false); // New state for showing details
    const accounts = ["Cash", "PayFast", "Bank", "Other"]; // Example accounts
    const { id, setId } = useQueryParams();

    useEffect(() => {
        calculateTotals(accountDetails);
    }, [accountDetails]);

    const fetchAccountDetail = async () => {
        const query = `GetAccountDetail?fromDate=${fromDate}&toDate=${toDate}&accountType=${selectedAccount}`;
        const response = await Common.ApiService.getInstance().request(query);

        if (response.status === respEnum.Success) {
            setAccountDetails(response.data);
        }
    };

    const calculateTotals = (data) => {
        const totals = data.reduce(
            (acc, row) => ({
                receive: acc.receive + row.receive,
                pay: acc.pay + row.pay,
                refund: acc.refund + row.refund,
            }),
            { receive: 0, pay: 0, refund: 0 }
        );
        setTotals(totals);
    };

    const handleSearch = () => {
        if (fromDate && toDate && selectedAccount) {
            setShowDetails(true); // Show the details card
            fetchAccountDetail();
        } else {
            alert("Please fill all fields!");
        }
    };

    return (
        <div className="space-y-6 mb-5">
            <Card title="Payment Account Balances">
                <div className="grid md:grid-cols-4 gap-4 p-5">
                    <div>
                        <label className="text-sm font-medium text-gray-500">From Date</label>
                        <input
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            className="w-full border-gray-300 rounded-md"
                        />
                    </div>
                    <div>
                        <label className="text-sm font-medium text-gray-500">To Date</label>
                        <input
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            className="w-full border-gray-300 rounded-md"
                        />
                    </div>
                    <div>
                        <label className="text-sm font-medium text-gray-500">Account Type</label>
                        <select
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                            className="w-full border-gray-300 rounded-md"
                        >
                            <option value="">Select Account</option>
                            {accounts.map((account, index) => (
                                <option key={index} value={account}>
                                    {account}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex items-end">
                        <button
                            onClick={handleSearch}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </Card>

            {showDetails && ( // Conditionally render the details card
                <Card title="Account Balance Details">
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                        Account
                                    </th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                        Receive
                                    </th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                        Pay
                                    </th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                                        Refund
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {accountDetails.map((detail, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {detail.account}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {detail.receive}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {detail.pay}
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            {detail.refund}
                                        </td>
                                    </tr>
                                ))}
                                {accountDetails.length > 0 && (
                                    <tr>
                                        <td className="px-6 py-4 text-sm font-bold text-gray-900">
                                            Total
                                        </td>
                                        <td className="px-6 py-4 text-sm font-bold text-gray-900">
                                            {totals.receive}
                                        </td>
                                        <td className="px-6 py-4 text-sm font-bold text-gray-900">
                                            {totals.pay}
                                        </td>
                                        <td className="px-6 py-4 text-sm font-bold text-gray-900">
                                            {totals.refund}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}
        </div>
    );
}
