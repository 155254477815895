import React, { createContext, useState, useContext, useEffect } from "react";
import {
  clearCachedQueryParams,
  getCachedQueryParams,
  setCachedQueryParams,
} from "../helper/sessionHelper";
const QueryParamsContext = createContext();

const initialValues = {
  bookingId: null,
  courtHolidayId: null,
  courtId: null,
  id: null
};

export const QueryParamProvider = ({ children }) => {
  const [params, setParams] = useState(initialValues);

  useEffect(() => {
    if (Object.values(params).some((val) => val)) {
      setCachedQueryParams(params);
    }
  }, [params]);

  useEffect(() => {
    var qParams = getCachedQueryParams();
    if (qParams) {
      setParams(qParams);
      clearCachedQueryParams();
    }
  }, []);

  const resetParams = () => {
    clearCachedQueryParams();
    setParams(initialValues);
  };

  const setId = id => setParams({...params, id});

  return (
    <QueryParamsContext.Provider value={{ params, id: params.id, setParams, resetParams,setId }}>
      {children}
    </QueryParamsContext.Provider>
  );
};

export const useQueryParams = () => {
  return useContext(QueryParamsContext);
};
