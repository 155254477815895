import React, { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormikInput } from "./index";

const PhoneInputField = ({
  formikProps,
  disabled,
  disableComponent,
  ...props
}) => {
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if (phoneInputRef.current) {
      const dropdownButton = phoneInputRef.current.dropdownContainerRef;

      if (dropdownButton) {
        const handleDropdownClick = () => {
          const currentPhoneCode = formikProps.values.phoneCode || "+92";
          formikProps.setFieldValue("phone", currentPhoneCode);
          formikProps.setFieldValue("phoneNum", "");
        };

        dropdownButton.addEventListener("click", handleDropdownClick);
        return () => {
          dropdownButton.removeEventListener("click", handleDropdownClick);
        };
      }
    }
  }, [formikProps]);

  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor="phone" className="font-medium mb-2">
          Phone
        </label>
      </div>
      {disabled ? (
        // Case 1: Render FormikInput when `disabled` is true
        <FormikInput formikProps={formikProps} disabled={true} />
      ) : disableComponent ? (
        <PhoneInput
          className="pl-2"
          ref={phoneInputRef}
          disabled={true}
          inputProps={{
            name: "ph",
            required: true,
            readOnly: true,
          }}
          value={formikProps.values.phone}
          inputStyle={{
            width: "100%",

            backgroundColor: "#e9ecef",
            color: "#6c757d",
            border: "1px solid #ced4da",
            cursor: "not-allowed",
            height: "32px ",
            fontSize: "13px",
            paddingLeft: "9px !important",
          }}
          buttonStyle={{
            display: "none",
          }}
          placeholder=""
          {...props}
        />
      ) : (
        <PhoneInput
          ref={phoneInputRef}
          country={"pk"}
          inputProps={{
            name: "phoneNum",
            required: true,
          }}
          value={formikProps.values.phone}
          onChange={(value, countryData) => {
            const phoneCode = `+${countryData.dialCode}`;
            const phoneNum = value.replace(countryData.dialCode, "").trim();
            formikProps.setFieldValue("phoneCode", phoneCode);
            formikProps.setFieldValue("phoneNum", phoneNum);
            formikProps.setFieldValue("phone", value);
          }}
          inputStyle={{
            width: "100%",
            fontSize: "11px",
            padding: "8px",
          }}
          buttonStyle={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "11px",
          }}
          placeholder="3081234567"
          {...props}
        />
      )}
      {formikProps.errors.phone && formikProps.touched.phone && (
        <div className="text-red-500 text-sm">{formikProps.errors.phone}</div>
      )}
    </div>
  );
};

export default PhoneInputField;
