import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import Common from "../../../helper/common";
import AdvanceTable from "../../../components/advanceTable";
import { useQueryParams } from "../../../contexts/queryParamsContext";
import { useNavigate } from "react-router-dom";
import { PaymentStatusEnum } from "../../../constants/appConstant";

const AdjustmentLog = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { params, setParams } = useQueryParams();
    const navigate = useNavigate();
    const { clubAdjustmentLogId } = useQueryParams().params;

    useEffect(() => {
        if (clubAdjustmentLogId) {
            getData();
        }
    }, [clubAdjustmentLogId]);

    const getData = async () => {
        debugger
        setLoading(true);
        let response = await Common.ApiService.getInstance().request(
            `GetAdjustmentLogs?id=${clubAdjustmentLogId}`
        );
        if(response?.data) {
            const data = response.data.map((item) => ({
                ...item,
                bookingDate: Common.Utility.ConvertDate(item.bookingDate),
                createdOn: Common.Utility.ConvertTime(item.createdOn),
                paymentStatusId: item.paymentStatus,
                paymentStatus: Common.Utility.getKeyByValue(
                    PaymentStatusEnum,
                    item.paymentStatus
                ),
            }));

            setData(data);
        }
        setLoading(false);
    };


    const actions = [
        {
            type: "view",
            onClick: (val) => {
                setParams({
                    ...params,
                    bookingId: val.bookingId,
                });
                navigate("/app/bookingDetails");
            },
        },
    ];

    const _thead = [
        { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "Booking Date", type: "text", isVisible: true, key: "bookingDate" },
        { title: "Time", type: "text", isVisible: true, key: "bookingDetail" },
        { title: "Court Name", type: "text", isVisible: true, key: "courtName" },
        { title: "Total", type: "text", isVisible: true, key: "total" },
        { title: "Remaining Amount", type: "text", isVisible: true, key: "remainingAmount" },
        //{ title: "Player Name", type: "text", isVisible: true, key: "playerName" },
        { title: "Adjustment Amount", type: "text", isVisible: true, key: "adjustAmount" },
        {
            title: "Payment Status",
            type: "text",
            key: "paymentStatus",
            isVisible: true,
            render: (record) =>
                Common.Utility.renderBadge(
                    record.paymentStatusId,
                    record.paymentStatus
                ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <>

            <div className="p-6 bg-white shadow-md rounded-lg border border-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
                    <div className="flex flex-col items-start p-4 bg-blue-50 rounded-lg">
                        <span className="text-gray-600 font-medium">Name:</span>
                        <span className="text-lg font-bold text-blue-900">
                            {data.length > 0 && data[0]?.playerName ? data[0].playerName : "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start p-4 bg-green-50 rounded-lg">
                        <span className="text-gray-600 font-medium">Total Adjustment:</span>
                        <span className="text-lg font-bold text-green-900">
                            {data.length > 0
                                ? Common.Utility.formatNumber(data.reduce((total, item) => total + (item.adjustAmount || 0), 0))
                                : 0}
                        </span>
                    </div>
                    <div className="flex flex-col items-start p-4 bg-yellow-50 rounded-lg">
                        <span className="text-gray-600 font-medium">Previous Balance:</span>
                        <span className="text-lg font-bold text-yellow-900">
                            {data.length > 0 && typeof data[0]?.balance === "number"
                                ? Common.Utility.formatNumber(data[0].balance)
                                : 0}
                        </span>
                    </div>
                    <div className="flex flex-col items-start p-4 bg-red-50 rounded-lg">
                        <span className="text-gray-600 font-medium">After Adjustment:</span>
                        <span className="text-lg font-bold text-red-900">
                            {data.length > 0 &&
                                typeof data[0]?.balance === "number" &&
                                typeof data.reduce((total, item) => total + (item.adjustAmount || 0), 0) ===
                                "number"
                                ? Common.Utility.formatNumber(data[0].balance -
                                    data.reduce((total, item) => total + (item.adjustAmount || 0), 0))
                                : 0}
                        </span>
                    </div>
                    <div className="flex flex-col items-start p-4 bg-purple-50 rounded-lg lg:col-span-1">
                        <span className="text-gray-600 font-medium">Adjust On:</span>
                        <span className="text-sm font-bold text-purple-900">
                            {data.length > 0 && data[0]?.createdOn ? data[0].createdOn : "-"}
                        </span>
                    </div>
                </div>
            </div>

            <AdvanceTable
                title={"Adjust Bookings"}
                columns={_thead.filter((item) => item.isVisible)}
                data={{ data: data }}
                getData={getData}
                showPagination={false}
                showAddButton
                actionButtons={actions}
            />
        </>
    );


  
};

export default AdjustmentLog;
