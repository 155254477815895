import { CardContent } from "../../../../components/ui/card";
import { Avatar, AvatarImage } from "../../../../components/ui/avatar";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import dayjs from "dayjs";
import Common from "../../../../helper/common";
import {
  MatchResultTypeEnum,
  PaymentTypeEnum,
  TransactionLogStatusEnum,
  DateTimeFormat,
  TransactionLogTypeEnum,
} from "../../../../constants/appConstant";

const BooingFurthurDetails = ({ booking }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (attachment) => {
    setModalContent(
      <img
        src={`${Common.AppConstant.imgBaseurl}${attachment}`}
        alt="Attachment"
        className="w-1/2 h-1/2 object-contain"
        style={{ width: "50%", height: "50%" }}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full flex flex-col  ">
      <CardContent className="flex-1 p-0 w-full  pb-5">
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Players</p>

          <Table>
            <TableHeader>
              <TableRow className="whitespace-nowrap">
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Side
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-left">
                  Player
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Is Creator
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Result
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Before Played Level
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  After Played Level
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.players?.map((player) => (
                <TableRow key={player.id}>
                  <TableCell className="border-r text-center">
                    {player.side}-{player.position}
                  </TableCell>
                  <TableCell className="text-center border-default-200">
                    <div className="flex items-center gap-3 pr-4">
                      <div className="flex items-center gap-2">
                        <Avatar className=" rounded-[2px] h-8 w-8">
                          <AvatarImage
                            src={`${Common.AppConstant.imgBaseurl}${player.playerImage ?? "noplayer-icon.jpg"
                              }`}
                          />
                          {/* <AvatarFallback>CD</AvatarFallback> */}
                        </Avatar>
                        <div className="text-sm  text-default-600 capitalize truncate max-w-[160px]">
                          {player.playerName}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="border-default-200 text-center">
                    {player.iscreator ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className="border-default-200  text-center">
                    {player.resultType
                      ? Common.Utility.getKeyByValue(
                        MatchResultTypeEnum,
                        player.resultType
                      )
                      : "-"}
                  </TableCell>
                  <TableCell className="border-default-200 text-center">
                    {player.beforePlayedLevel ?? "-"}
                  </TableCell>
                  <TableCell className="border-default-200 text-center">
                    {player.afterPlayedLevel ?? "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <br />
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Payment Details</p>
          <Table>
            <TableHeader>
              <TableRow className="whitespace-nowrap">
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Player
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Payment Type
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Account Name
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Payment Status
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Amount
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Is By Club
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Receipt
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.transactionLogs?.filter(x => x.transactionType != TransactionLogTypeEnum.PromoCode_Redeemed && x.transactionType != TransactionLogTypeEnum.Club_Discount).length > 0 ? (
                booking.transactionLogs
                  .filter(x => x.transactionType != TransactionLogTypeEnum.PromoCode_Redeemed && x.transactionType != TransactionLogTypeEnum.Club_Discount)
                  .map((tlog, index) => (
                    <TableRow key={index}>
                      <TableCell className="border-r text-center border-default-200">
                        {tlog.playerName}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {tlog?.paymentTypeName || "-"}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {tlog?.clubAccountName || "-"}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {Common.Utility.getKeyByValue(
                          TransactionLogStatusEnum,
                          tlog.status
                        )}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {tlog.amount}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {tlog.isByClub ? "Yes" : "No"}
                      </TableCell>

                      <TableCell className="border-r border-default-200 text-center">
                        {tlog.attachment ? (
                          <img
                            src={`${Common.AppConstant.imgBaseurl}${tlog.attachment}`}
                            alt="Attachment"
                            className="cursor-pointer rounded"
                            style={{ width: "50px", height: "50px" }}
                            onClick={() => handleImageClick(tlog.attachment)}
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center">
                    No payment received
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </div>


        {booking?.transactionLogs?.length > 0 &&

          (<div className="overflow-x-auto mt-5">
            <p className="font-bold text-center text-xl">Discount </p>
            <Table>
              <TableHeader>
                <TableRow className="whitespace-nowrap">
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Player
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Type
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Promo Code
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Discount
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Amt
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Avail
                  </TableHead>
                  <TableHead className="text-sm text-default-800 border-default-200 text-center">
                    Capped
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody className="[&_tr:last-child]:border-1">
                {booking?.transactionLogs?.filter(x => x.transactionType == TransactionLogTypeEnum.PromoCode_Redeemed || x.transactionType == TransactionLogTypeEnum.Club_Discount).length > 0 ? (
                  booking?.transactionLogs
                    .filter(x => x.transactionType == TransactionLogTypeEnum.PromoCode_Redeemed || x.transactionType == TransactionLogTypeEnum.Club_Discount)
                    .map((tlog, index) => (
                      <TableRow key={index}>
                        <TableCell className="border-r text-center border-default-200">
                          {tlog.playerName}
                        </TableCell>

                        <TableCell className="border-r text-center border-default-200">

                          {Common.Utility.getKeyByValue(
                            TransactionLogTypeEnum,
                            tlog.transactionType
                          )}
                        </TableCell>


                        <TableCell className="border-r border-default-200 text-center">
                          {tlog?.promocode || "-"}
                        </TableCell>

                        <TableCell className="border-r border-default-200 text-center">
                          {tlog?.promocodeAmt || "-"}
                        </TableCell>

                        <TableCell className="border-r border-default-200 text-center">
                          {tlog?.orderAmount || "-"}
                        </TableCell>

                        <TableCell className="border-r border-default-200 text-center">
                          {tlog.redeemPercent} %
                        </TableCell>

                        <TableCell className="border-r border-default-200 text-center">
                          {tlog.isCapped ? "Yes" : "No"}
                        </TableCell>

                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center">
                      No payment received
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

            </Table>
          </div>)

        }


        <br />
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Score Board</p>
          <Table>
            <TableHeader>
              <TableRow className="whitespace-nowrap">
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Side
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 1
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 2
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 3
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.groupedResults &&
                Object.keys(booking.groupedResults).length > 0 ? (
                Object.entries(booking.groupedResults).map(
                  ([side, results], index) => {
                    // Initialize sets
                    const sets = { set1: "-", set2: "-", set3: "-" };

                    // Map results to the correct set based on the round
                    results.forEach((result) => {
                      if (result.round === 1) sets.set1 = result.points;
                      if (result.round === 2) sets.set2 = result.points;
                      if (result.round === 3) sets.set3 = result.points;
                    });

                    return (
                      <TableRow key={index}>
                        <TableCell className="border-r text-center border-default-200">
                          {side}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set1}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set2}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set3}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    Result not uploaded
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <br />
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Reschedule Details</p>
          <Table>
            <TableHeader>
              <TableRow className="whitespace-nowrap">
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Court Name
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Previous Start time
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Previous End time
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  New Start time
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  New End time
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Court Changed
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.reschedulings?.length > 0 ? (
                booking.reschedulings.map((resc, index) => (
                  <TableRow key={index}>
                    <TableCell className="border-r text-center border-default-200">
                      {resc.courtName}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {resc?.previousStartDateTime
                        ? dayjs(resc.previousStartDateTime).format(
                          DateTimeFormat
                        )
                        : "-"}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {resc?.previousEndDateTime
                        ? dayjs(resc.previousEndDateTime).format(DateTimeFormat)
                        : "-"}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {resc?.newStartDateTime
                        ? dayjs(resc.newStartDateTime).format(DateTimeFormat)
                        : "-"}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {resc?.newEndDateTime
                        ? dayjs(resc.newEndDateTime).format(DateTimeFormat)
                        : "-"}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {resc.isCourtChanged ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    No Booking Reschedule
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-5 rounded-lg shadow-lg relative w-[90%] md:w-[60%] lg:w-[40%] h-[90%] md:h-[90%] lg:h-[80%] overflow-y-auto">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
              >
                ✕
              </button>
              <h2 className="text-lg font-bold mb-4 text-center">Receipt</h2>
              <div className="text-center">{modalContent}</div>
            </div>
          </div>
        )}
      </CardContent>
    </div>
  );
};

export default BooingFurthurDetails;
