import { Icon } from "@iconify/react";
import { cn } from "../../../../helper/utilityHelper";
import { Button } from "../../../../components/ui/button";
import moment from "moment";

import Common from "../../../../helper/common";
import {
  BookingModeEnum,
  BookingPaymentMethodEnum,
  BookingStatusEnum,
  BookingTypeEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
} from "../../../../constants/appConstant";

const BookingDetailSidebar = ({
  booking,
  setShowAddPayment,
  getBookingDetails,
  setShowReschedule,
  setShowAddDiscount,
}) => {
  const cancelledBooking = async () => {
    let data = {
      bookingId: +booking?.id,
      status: +BookingStatusEnum.Cancelled_By_Club,
    };

    let resp = await Common.ApiService.getInstance().request(
      "UpdateBookingStatus",
      data,
      "Patch"
    );
    Common.SweetAlert.alert(resp.message, "success");
    setTimeout(() => {
      getBookingDetails();
    }, 500);
  };

  const bookingInfo = [
    {
      icon: "heroicons:calendar",
      label: "Booking Mode",
      value: booking?.bookingMode
        ? Common.Utility.getKeyByValue(BookingModeEnum, booking.bookingMode)
        : null,
    },
    {
      icon: "heroicons:calendar",
      label: "Booking Date",
      value: booking?.bookingDate
        ? Common.moment(booking.bookingDate).format("DD MMM yyyy")
        : null,
    },
    {
      icon: "heroicons:clock",
      label: "Time",
      value: booking?.bookingDetail,
    },
    {
      icon: "heroicons:map",
      label: "Court",
      value: booking?.courtName,
    },

    {
      icon: "heroicons:user",
      label: "Booked By",
      value: booking?.bookedBy,
    },

    {
      icon: "heroicons:user",
      label: "Player Cell #",
      value: booking?.playerCellNo ?? "-",
    },

    // {
    //   icon: "heroicons:exclamation-circle",
    //   label: "Type",
    //   value: booking?.bookingStatus
    //     ? Common.Utility.getKeyByValue(BookingTypeEnum, booking.bookingType)
    //     : null,
    // },
    {
      icon: "heroicons:shield-check",
      label: "Payment Mode",
      value: booking?.paymentTypeId
        ? Common.Utility.getKeyByValue(PaymentTypeEnum, booking.paymentTypeId)
        : null,
      shouldRender:
        booking?.paymentTypeId !== null &&
        booking?.paymentTypeId == PaymentTypeEnum.Pay_On_Club,
    },
    {
      icon: "heroicons:shield-check",
      label: "Status",
      value: booking?.bookingStatus
        ? Common.Utility.getKeyByValue(BookingStatusEnum, booking.bookingStatus)
        : null,
    },
    {
      icon: "heroicons:shield-check",
      label: "Payment Status",
      value: booking?.bookingStatus
        ? Common.Utility.getKeyByValue(PaymentStatusEnum, booking.paymentStatus)
        : null,
    },
    // {
    //   icon: "heroicons:shield-check",
    //   label: "No of Player",
    //   value: booking?.noofplayers,
    // },
    // {
    //   icon: "heroicons:shield-check",
    //   label: "Level Range",
    //   value:
    //     booking?.bookingMode == BookingModeEnum.App_Booking
    //       ? `${booking?.fromLevel} - ${booking.tillLevel}`
    //       : "-",
    // },
    // {
    //   icon: "heroicons:shield-check",
    //   label: "Payment Method",
    //   value: booking?.paymentMethod
    //     ? Common.Utility.getKeyByValue(
    //         BookingPaymentMethodEnum,
    //         booking.paymentMethod
    //       )
    //     : null,
    // },
    {
      icon: "heroicons:shield-check",
      label: "Court Charges",
      value: booking?.courtcharges,
    },
    // {
    //   icon: "heroicons:shield-check",
    //   label: "PlatForm Fee",
    //   value: booking?.platformfee ?? "0",
    // },
    {
      icon: "heroicons:shield-check",
      label: `Discount ${booking?.promocode ? `(Promocode: ${booking.promocode})` : ""}`,
      value: booking?.discountAmount,
    },
    // {
    //   icon: "heroicons:shield-check",
    //   label: "Promo Code",
    //   value: booking?.promocode,
    // },
    {
      icon: "heroicons:shield-check",
      label: "Total",
      value: booking?.total,
    },
    {
      icon: "heroicons:shield-check",
      label: "Receive Amount",
      value: booking?.receivedAmount,
    },
    {
      icon: "heroicons:shield-check",
      label: "Remaining Amount",
      value: booking?.remainingAmount,
    },
  ];

  return (
    <>
      <ul className=" ">
        {bookingInfo
          .filter((item) => item.shouldRender !== false)
          .map((item, index) => (
            <li
              key={`filter-key-${index}`}
              className={cn(
                "cursor-pointer  flex justify-between items-center gap-1.5 p-2 rounded group hover:bg-primary/10"
              )}
            >
              <span
                className={cn(
                  "flex  items-center gap-1.5  rounded group text-sm font-medium text-default-600 group-hover:text-primary",
                  ""
                )}
              >
                <Icon
                  icon={item.icon}
                  className={cn(
                    "w-4 h-4 text-default-600 group-hover:text-primary",
                    ""
                  )}
                />
                {item.label} :
              </span>

              <span
                className={cn(
                  "text-sm font-medium text-end text-default-600 group-hover:text-primary",
                  ""
                )}
              >
                {item.value}
              </span>
            </li>
          ))}
      </ul>
      {/* <div className="border border-dashed mt-3 border-default-300"></div> */}
      <div className="mt-3 md:px-4 px-2">
        {/* <div className="text-xs font-medium text-default-800 uppercase">
          Action
        </div> */}

        {/* <ul className="mt-3">
          {priorityFilters.map((item, index) => (
            <li
              key={`priority-item-${index}`}
              className="flex justify-between gap-2 space-y-4 cursor-pointer"
            >
              <div className="flex items-center gap-2">
                <span
                  className={cn(
                    `w-2 h-2 rounded-full block bg-${item.color} ring-${item.color}`,
                    {}
                  )}
                ></span>
                <span className="text-sm font-medium text-default-600">
                  {item.label}
                </span>
              </div>
              <span className="text-sm font-medium text-default-600">
                {item.total}
              </span>
            </li>
          ))}a
        </ul> */}

        {/* // (booking?.bookingMode === BookingModeEnum.Club_Booking ||
        //   (booking?.bookingMode === BookingModeEnum.App_Booking &&
        //     booking?.paymentTypeId === PaymentTypeEnum.Pay_On_Club || booking?.paymentTypeId === PaymentTypeEnum.Online_Partial_Pay)) &&
        // booking?.bookingStatus === BookingStatusEnum.Booked   */}


        <div className="mt-3 md:px-4 px-2 fixed bottom-0 ml-[260px] bottom-btns bg-white z-50 left-0 py-3 border-default-300">
          {!booking?.isPaid && booking?.bookingStatus == BookingStatusEnum.Booked && booking?.bookingType == BookingTypeEnum.Private &&
            (
              <Button
                color="success"
                className="mr-3"
                onClick={() => setShowAddPayment(true)}
              >
                Add Payment
              </Button>
            )}

          {booking?.bookingStatus === BookingStatusEnum.Booked && booking?.bookingType == BookingTypeEnum.Private &&
            !booking?.isPaid && Common.Utility.ShowOnValidateDateOperation(booking, 'startDateTime', '>=', true, 48)
            //             booking?.paymentStatus === PaymentStatusEnum.Un_Paid &&
            // moment().isBetween(
            // moment(booking?.bookingDate).subtract(2, "days").startOf("day"), // 2 days before booking date
            // moment(booking?.bookingDate).endOf("day"),                      // End of the booking date
            // undefined,
            // "[]"
            // )
            && (
              <Button
                color="warning"
                className="mr-3"
                onClick={() => setShowAddDiscount(true)}
              >
                Add Discount
              </Button>
            )}





          {booking?.bookingStatus === BookingStatusEnum.Booked &&
            moment(booking?.startDateTime).isSameOrAfter(
              Common.Utility.GetCurrentDateTime()
            ) && (
              <Button
                color="info"
                className="mr-3"
                onClick={() => setShowReschedule(true)}
              >
                Reschedule
              </Button>
            )}

          {/* && booking?.bookingMode == BookingModeEnum.Club_Booking */}
          {/*{booking?.bookingStatus === BookingStatusEnum.Booked &&*/}
          {/*  new Date(booking.startDateTime) > new Date() && (*/}
          {/*    <Button*/}
          {/*      color="destructive"*/}
          {/*      onClick={async () => {*/}
          {/*        const isConfirmed = await Common.SweetAlert.confirmation(*/}
          {/*          "Are you sure you want to cancel this booking? This can't be reversed!",*/}
          {/*          "Confirm Cancel"*/}
          {/*        );*/}
          {/*        if (isConfirmed) {*/}
          {/*          cancelledBooking();*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Cancel Booking*/}
          {/*    </Button>*/}
          {/*            )}*/}


          {booking?.bookingStatus === BookingStatusEnum.Booked &&
            Common.Utility.ShowOnValidateDateOperation(booking, 'startDateTime', '>=', true, 11115558) &&
            (
              <Button
                color="destructive"
                onClick={async () => {
                  const isConfirmed = await Common.SweetAlert.confirmation(
                    "Are you sure you want to cancel this booking? This can't be reversed!",
                    "Confirm Cancel"
                  );
                  if (isConfirmed) {
                    cancelledBooking();
                  }
                }}
              >
                Cancel Booking
              </Button>
            )
          }


        </div>
      </div >
    </>
  );
};

export default BookingDetailSidebar;
