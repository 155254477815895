import React, { useState } from 'react';
import { ShoppingCart, Tag, Package, Search } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';

const POSSystem = () => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const categories = [
    { id: 'all', name: 'All' },
    { id: 'main', name: 'Main Course' },
    { id: 'appetizer', name: 'Appetizers' },
    { id: 'drinks', name: 'Drinks' },
    { id: 'desserts', name: 'Desserts' }
  ];

  const products = [
    { id: 1, name: "Chicken Karahi", price: 1200, category: 'main', stock: 20 },
    { id: 2, name: "Biryani", price: 250, category: 'main', stock: 50 },
    { id: 3, name: "Seekh Kabab", price: 300, category: 'appetizer', stock: 40 },
    { id: 4, name: "Nihari", price: 400, category: 'main', stock: 25 },
    { id: 5, name: "Coca Cola", price: 100, category: 'drinks', stock: 100 },
    { id: 6, name: "Mint Margarita", price: 150, category: 'drinks', stock: 30 },
    { id: 7, name: "Zinger Burger", price: 350, category: 'main', stock: 45 },
    { id: 8, name: "Club Sandwich", price: 300, category: 'main', stock: 35 },
    { id: 9, name: "Lab-e-Shireen", price: 200, category: 'desserts', stock: 20 },
    { id: 10, name: "Gulab Jamun", price: 150, category: 'desserts', stock: 40 },
    { id: 11, name: "Spring Rolls", price: 200, category: 'appetizer', stock: 60 },
    { id: 12, name: "Chicken Soup", price: 180, category: 'appetizer', stock: 30 },
    { id: 13, name: "Kheer", price: 150, category: 'desserts', stock: 25 },
    { id: 14, name: "Lassi", price: 120, category: 'drinks', stock: 50 },
    { id: 15, name: "Fries", price: 150, category: 'appetizer', stock: 100 },
  ];

  const filteredProducts = products
    .filter(p => selectedCategory === 'all' || p.category === selectedCategory)
    .filter(p => p.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const addToCart = (product) => {
    const existingItem = cart.find(item => item.id === product.id);
    if (existingItem) {
      if (existingItem.quantity >= product.stock) {
        alert('Stock limit reached!');
        return;
      }
      setCart(cart.map(item =>
        item.id === product.id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ));
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
    setTotal(prev => prev + product.price);
  };

  const removeFromCart = (product) => {
    const existingItem = cart.find(item => item.id === product.id);
    if (existingItem.quantity === 1) {
      setCart(cart.filter(item => item.id !== product.id));
    } else {
      setCart(cart.map(item =>
        item.id === product.id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      ));
    }
    setTotal(prev => prev - product.price);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="flex-1 grid grid-cols-1 lg:grid-cols-4 gap-6 p-6">
        {/* Left Sidebar */}
        <div className="lg:col-span-1 flex flex-col gap-4">
          <Card className="shadow-lg">
            <CardHeader className="pb-2 bg-[#2E55B9] text-white rounded-t-lg">
              <CardTitle>Categories</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-2">
                {categories.map((cat) => (
                  <button
                    key={cat.id}
                    onClick={() => setSelectedCategory(cat.id)}
                    className={`p-3 rounded-lg flex items-center gap-2 text-left transition-all duration-200 transform hover:scale-105
                      ${selectedCategory === cat.id
                        ? 'bg-[#2E55B9] text-white shadow-md'
                        : 'bg-white hover:bg-[#2E55B9] hover:text-white border border-gray-200'}`}
                  >
                    <Tag size={16} />
                    {cat.name}
                  </button>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Main Content */}
        <div className="lg:col-span-2 flex flex-col gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#2E55B9]" size={20} />
            <input
              type="text"
              placeholder="Search products..."
              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-[#2E55B9] focus:ring-2 focus:ring-[#2E55B9]/20 transition-all duration-200"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Card className="flex-1 shadow-lg">
            <CardContent className="p-6">
              <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {filteredProducts.map((product) => (
                  <button
                    key={product.id}
                    onClick={() => addToCart(product)}
                    disabled={product.stock === 0}
                    className={`group relative p-4 border-2 rounded-xl text-left transition-all duration-200 
                      ${product.stock === 0
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:border-[#2E55B9] hover:shadow-lg hover:scale-105 hover:bg-white'}`}
                  >
                    <div className="font-medium text-lg group-hover:text-[#2E55B9] truncate">{product.name}</div>
                    <div className="text-sm text-gray-600 mt-1 font-semibold">PKR {product.price}</div>
                    <div className="text-xs flex items-center gap-1 text-gray-500 mt-2">
                      <Package size={12} />
                      {product.stock}
                    </div>
                    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                      <div className="bg-[#2E55B9] text-white p-1 rounded-full">
                        <ShoppingCart size={16} />
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Sidebar - Cart */}
        <div className="lg:col-span-1">
          <Card className="h-full shadow-lg">
            <CardHeader className="bg-[#2E55B9] text-white rounded-t-lg">
              <CardTitle className="flex items-center gap-2">
                <ShoppingCart /> Cart
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-3">
                {cart.map((item) => (
                  <div key={item.id} className="flex items-center justify-between p-3 border-2 rounded-lg hover:border-[#2E55B9] transition-all duration-200">
                    <div className="flex-1">
                      <div className="font-medium">{item.name}</div>
                      <div className="text-sm text-gray-600">
                        PKR {item.price} x {item.quantity}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => removeFromCart(item)}
                        className="w-8 h-8 flex items-center justify-center text-red-500 hover:bg-red-50 rounded-full transition-colors"
                      >
                        -
                      </button>
                      <span className="w-8 text-center font-medium">{item.quantity}</span>
                      <button
                        onClick={() => addToCart(item)}
                        className="w-8 h-8 flex items-center justify-center text-[#2E55B9] hover:bg-[#2E55B9]/10 rounded-full transition-colors"
                        disabled={item.quantity >= item.stock}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}

                {cart.length === 0 && (
                  <div className="text-center text-gray-500 py-8">
                    <ShoppingCart size={48} className="mx-auto mb-4 text-gray-400" />
                    <p>Cart is empty</p>
                  </div>
                )}

                <div className="mt-6 p-4 bg-gray-50 rounded-xl border-2">
                  <div className="flex items-center justify-between font-medium text-lg">
                    <span>Total:</span>
                    <span className="text-[#2E55B9]">PKR {total.toFixed(2)}</span>
                  </div>
                  <button
                    className="mt-4 w-full bg-[#2E55B9] text-white p-3 rounded-lg hover:bg-[#2E55B9]/90 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 font-medium"
                    disabled={cart.length === 0}
                  >
                    Complete Order
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};


export default POSSystem;